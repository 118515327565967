<template>
  <Row v-if="vendorProps"
       justify="space-around"
       class="map-vendor-poptip"
  >
    <Row v-if="!selectedVendor">
      <div style="text-align:center; width:100%">
        <p>This vendor</p>
        <br>
        <p><strong>{{ ellipsis(vendorProps.alias) || ellipsis(vendorProps.host) }} </strong></p>
        <br>
        <p>Is unknow</p>
      </div>
      <Divider />
      <div class="container-1">
        <p>Suggestion from Agnostik library ({{ suggestVendors.length }} vendors)</p>
        <br>
        <ul v-if="suggestVendors.length">
          <li v-for="(val, key) of suggestVendors"
              :key="key"
              class="list-item"
              @click="selectVendor(val.vendor)"
          >
            {{ ellipsis(val.vendor.name) }}
            <Icon type="md-add" />
          </li>
        </ul>
        <br>
        <br>
        <p>Or find another one</p>
        <br>
        <Input type="text"
               style="width:100%;display:block;"
               suffix="ios-search"
               placeholder="Vendor name"
               v-model="inputName"
               @input="debounceInput"
        />
        <ul v-if="searchVendors.length">
          <li v-for="(val, key) of searchVendors"
              :key="key"
              class="list-item"
              @click="selectVendor(val)"
          >
            {{ ellipsis(val.name) }}
            <Icon type="md-add" />
          </li>
        </ul>
        <br>
        <p>
          If you don’t find a matching vendor,<br>
          contact Agnostik customer support :
        </p>
      </div>
      <a HREF="mailto:support@agnostik.io"
         style="display:block;text-align:center;width:100%;margin-top:24px"
      >support@agnostik.io</a>
    </Row>
    <Row v-else>
      <div style="text-align:center; width:100%; position:relative">
        <i class="fas fa-arrow-left"
           style="positon:aboslute;cursor:pointer;left:-45px;top:-5px;font-size:14px;font-weight:bold;position:absolute"
           @click="selectedVendor = !selectedVendor"
        />
        <p>This unknow vendor</p>
        <br>
        <p><strong>{{ ellipsis(vendorProps.alias) || ellipsis(vendorProps.host) }} </strong></p>
        <br>
        <p> will be linked to</p>
        <br>
        <p style="text-align:center;font-weight:700; padding:8px; background:#ebebeb">
          {{ ellipsis(selectedVendor.name) }}
        </p>
        <br>
        <p>Do you want to confirm ?</p>
        <br>
        <Button type="primary"
                @click="linkVendor"
        >
          Confirm
        </Button>
      </div>
    </Row>
  </Row>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as _ from 'lodash'
import { eventBus } from '@/main'

export default {
  name: 'ManualMapVendor',
  props: {
    vendorProps: {
      type: Object,
      note: 'The unkown vendor',
      default: null
    }
  },
  data () {
    return {
      bus: eventBus,
      suggestVendors: [],
      searchVendors: [],
      selectedVendor: null,
      inputName: '',
      vendorVlModel: {
        name: "",
        privacy_policy_url: "",
        category_name: "",
        country_name: "",
        country_protection: "",
        iabvendor_active: null,
        iabvendor_declare_flexible_purposes: null,
        iabvendor_declare_legitimate_interest_purposes: null,
        id: 0,
        tcfv2_id: null,
        uuid: "",
        vigilance_rating:0,
      }
    }
  },
  watch: {
    vendorProps: {
      deep: true,
      handler: function (val, old) {
        this.inputName = ''
        this.selectedVendor = null
        this.suggestVendors = []
        this.searchVendors = []
        this.findBestVendorMatching(val)
      }
    }
  },
  computed: {
    ...mapGetters({
      vendorList: 'vendor/vendorList',
      draftVendorList: 'vendor/draftVendorList'
    })
  },
  methods: {
    ...mapActions({
      getVendorDomains: 'vendor/getVendorDomains',
      getVendors : 'vendor/getVendors'
    }),
    ...mapMutations({
      updateUnknowVendor: 'vendor/UPDATE_UNKNOW_VENDOR'
    }),
    linkVendor () {
      const vendor = this.selectedVendor
      const base = JSON.parse(JSON.stringify(this.vendorVlModel))
      base.country_name = vendor.country ? vendor.country.name : 'Undefined'
      base.country_protection =  vendor.country ? vendor.country.protection : 'Undefined'
      base.category_name = vendor.category ? vendor.category.name : 'N/A'
      base.declared_in_vl = this.vendorProps.action === 'add' ? 1 : 0
      base.detected_in_cmp = this.vendorProps.action === 'add' ? 1 : 0
      base.detected_in_requests = this.vendorProps.detected_in_requests
      base.iabvendor_active = !!vendor.iabv2Id
      base.id = vendor.id
      base.name = vendor.name
      base.tcfv2_id = vendor.iabv2Id
      base.uuid = vendor.uuid
      base.vigilance_rating = vendor.vigilance_rating
      base.mappedTo = this.vendorProps
      if (this.vendorProps.host) base.host = this.vendorProps.host
      if (this.vendorProps.alias) base.alias = this.vendorProps.alias
      this.updateUnknowVendor(base)
      this.$emit('vendor-linked', base)
    },
    selectVendor (vendor) {
      this.selectedVendor = vendor
    },
    ellipsis (string = '') {
      if (string && string.length > 25) {
        return string.substring(0, 22) + '...'
      }
      return string
    },
    debounceInput: _.debounce(function (e) {
      this.viewedVendor = null
      const params = {
        category: true,
        country: true,
        count: 1,
        limit: 10,
        q: this.inputName.trim()
      }
      return this.getVendors(params)
        .then(r => {
          if (r.success) {
            this.searchVendors = r.data
          }
        })
    }, 500),
    findBestVendorMatching (vendor) {
      const host = vendor.host
      if (host) {
        return this.getVendorDomains({
          q: host
        }).then(r => {
          if (r.success) {
            this.suggestVendors = r.data.filter(elm => elm.vendor)
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">

    .map-vendor-poptip {
        .container-1 {
            padding: 0 24px;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
        }
        ul {
            width: 100%;
        }
        .list-item {
            padding:8px;
            background:rgb(235 235 235 / 37%);
            cursor:pointer;display:flex;
            align-items:center;
            justify-content:space-between;
            &:hover {
                background: #ebebeb;
            }
        }
    }
</style>
