<template>
  <main style="padding:24px">
    <div v-if="step === 0">
      <p>
        Welcome to your Vendor List Manager. <br><br>
        Here, you're able to monitor all the vendor activity on your website, and manage vendors whether they're active and/or declared in your CMP.
      </p>
      <br><br>
      <Alert type="warning"
             show-icon
      >
        Provided metrics are related to the selected period
      </Alert>

      <br>
      <img :src="img1Url"
           style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
      >
    </div>
    <div v-if="step === 1">
      <p>
        Clicking on one of the indicators to display additional data
      </p>
      <p>For example, clicking on the "Active but not in CMP" indicator will display a detailed list of vendors that are active on your properties but not declared in your CMP:</p>
      <img :src="img2Url"
           style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
      >
    </div>
    <Row v-if="step === 2">
      <Row>
        <p>
          For more granular information, you can search for a specific vendor:
        </p>
        <img :src="img3Url"
             style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
        >
      </Row>
    </Row>
    <Row v-if="step === 3">
      <Row>
        <p>
          Add or remove vendors using the + and - icons.
        </p>
        <img :src="img4Url"
             style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
        >
      </Row>
    </Row>
    <Row v-if="step === 4">
      <Row>
        <p>Adding or removing vendors will impact the indicators next time your site is scanned</p>
        <img :src="img5Url"
             style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
        >
      </Row>
    </Row>
    <Row v-if="step === 5">
      <Row>
        <p>You can then export your new vendor list by clicking the "Export the Vendor list Project" button and implement it in your CMP</p>
        <img :src="img7Url"
             style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
        >
        <img :src="img8Url"
             style="width:100%; margin:42px auto; border:1px solid rgba(0,0,0,0.2)"
        >
      </Row>
    </Row>
    <Row type="flex"
         style="margin-top:24px"
    >
      <Steps :current="step"
             style="width: 300px;margin:0 auto;"
      >
        <Step @click.native="step = 0"
              style="cursor:pointer"
        />
        <Step @click.native="step = 1"
              style="cursor:pointer"
        />
        <Step @click.native="step = 2"
              style="cursor:pointer"
        />
        <Step @click.native="step = 3"
              style="cursor:pointer"
        />
        <Step @click.native="step = 4"
              style="cursor:pointer"
        />
        <Step @click.native="step = 5"
              style="cursor:pointer"
        />
      </Steps>
      <Button type="primary"
              style="position:absolute;bottom: 15px;right:15px"
              @click="step === 5 ? $emit('close-help') : next()"
      >
        {{ step === 5 ? 'Done' : 'Next step' }}
      </Button>
    </Row>
  </main>
</template>
<script>
import img1 from '@/assets/images/step1-vlm.png'
import img2 from '@/assets/images/step2-vlm.png'
import img3 from '@/assets/images/step3-vlm.png'
import img4 from '@/assets/images/step4-vlm.png'
import img5 from '@/assets/images/step5-vlm.png'
import img6 from '@/assets/images/step6-vlm.png'
import img7 from '@/assets/images/step7-vlm.png'
import img8 from '@/assets/images/step8-vlm.png'


export default {
  data () {
    return {
      step: 0,
      img1Url: img1,
      img2Url: img2,
      img3Url: img3,
      img4Url: img4,
      img5Url: img5,
      img6Url: img6,
      img7Url: img7,
      img8Url: img8
    }
  },
  methods: {
    next () {
      if (this.step == 5) {
        this.step = 0
      } else {
        this.step += 1
      }
    }
  }

}
</script>
