<template>
  <div>
    <FilterTable
      v-if="triggerTableUpdate"
      :columns="vendorCols"
      :fetch-items="fetchItems"
      :trigger="triggerTableRefresh"
      :show-columns-selector="false"
      :allow-download="false"
      :limit="pagination.limit"
      :count="true"
      :is-vendor-list-filters="true"
      :show-title="false"
      :container="true"
      :is-table-loading="vendorsPack[activeTable].isLoading"
      :pagination-ref="'vendor-list'"
      ref="vendorListTable"
    />
    <VendorDetailDrawer :is-open="isOpenDrawer"
                        @on-close="isOpenDrawer = false"
                        :vendor-props="selectedVendor"
    />
    <Modal
      draggable
      v-if="!!vendorToMap"
      v-model="isMappingVendor"
      title="Unknown vendor"
      footer-hide
      width="286px"
    >
      <ManualMapVendor :vendor-props="vendorToMap"
                       ref="mapping-vendor"
                       :is-open="isMappingVendor"
                       @vendor-linked="handleLinkingVendor"
      />
      <div slot="footer" />
    </Modal>
  </div>
</template>
<script>
import ManualMapVendor from './ManualMapVendor.vue'
import tableColumn from '@/mixins/tableColumn'
import FilterTable from '@/components/ui/FilterTable'
import VendorDetailDrawer from './VendorDetailDrawer.vue'
import { mapGetters, mapMutations } from 'vuex'
import { eventBus } from '@/main'
export default {
  mixins: [tableColumn],
  components: {
    FilterTable,
    VendorDetailDrawer,
    ManualMapVendor
  },
  props: {
    activeTable: {
      type: [Boolean, String],
      note: 'define selected prefiltered view/table',
      default: false
      /*
        totalDeclared,
        totalDetected,
        nonDeclaredActive,
        totalDetectedActive,
        declaredNonActive
       */
    }
  },
  watch: {
    activeTable: {
      immediate: true,
      handler: function (val, _) {
        if (val === 'totalDetected') {
          this.isVendorListDeclareAction = true
        } else {
          this.isVendorListDeclareAction = false
        }
        this.pagination = {
          count: 0,
          limit: 20,
          sort: '',
          page: 1,
          current_page: 1,
          direction: ''
        }
        this.triggerTableRefresh = !this.triggerTableRefresh
      }
    }
  },
  data () {
    return {
      vendorToMap: null,
      isMappingVendor: false,
      triggerTableRefresh: false,
      triggerTableUpdate: true,
      bus: eventBus,
      vendorCols: this.getVendorCols(),
      isVendorListDeclareAction: this.showVendorlistDeclareAction,
      isOpenDrawer: false,
      selectedVendor: null,
      pagination: {
        count: 0,
        limit: 20,
        sort: '',
        page: 1,
        current_page: 1,
        direction: ''
      },
      filters: null,
      vendorsPack: {
        vendors: [],
        totalDetected: {
          isLoading: true
        },
        nonDeclaredActive: {
          isLoading: true
        },
        totalDeclared: {
          isLoading: true
        },
        totalDetectedActive: {
          isLoading: true
        },
        declaredNonActive: {
          isLoading: true
        }
      },
      actualFilteredList: [],
      copyVendors: [],

      // array used for display
      selectedPrefilteredList : [],
      selectedFilteredList : [],


    }
  },
  mounted () {
    const that = this
    this.bus.$on('fetched-vendor', (vendorsPack) => {
      console.log('fetched-vendors')
      that.vendorsPack = vendorsPack
      that.copyVendors = JSON.parse(JSON.stringify(vendorsPack.vendors))
      that.triggerTableRefresh = !that.triggerTableRefresh
    })
    this.bus.$on('paginate-vendor-list', (pagination) => {
      console.log('receive pagination', pagination)
      that.pagination = pagination
      that.pagination.current_page = pagination.page
    })
    this.bus.$on('trigger-filter', (filters) => {
      console.log('trigger-filters')
      if (filters !== null) {
        this.filters = filters
      }

      that.triggerTableRefresh = !that.triggerTableRefresh
    })
  },
  computed: {
    ...mapGetters({
      scopeSelectedProperty: 'scope/selectedProperty',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      showCategoryHelper: 'vendor/showCategoryHelper',
      complianceColor: 'vendor/riskColor',

      draftVendorlist: 'vendor/draftVendorList',
      vendorList: 'vendor/vendorList',
      nonDeclaredDraftVendorList: 'vendor/nonDeclaredDraftVendorList'
    }),
    getDetectedInCMP: () => {

      return
    }
  },
  methods: {
    ...mapMutations({
      addVendor: 'vendor/PUSH_TO_DRAFT_VENDORLIST',
      removeVendor: 'vendor/REMOVE_FROM_DRAFT_VENDORLIST',
      setVendors: 'vendor/SET_DRAFT_VENDORLIST',
      removeDuplicateVendors: 'vendor/DEDUPLICATE_VENDORS'
    }),

    handleKpiChange () {
      const table = this.$refs['vendorListTable']
      this.pagination.current_page = 1
      this.pagination.page = 1
      table && table.fetchData()
    },
    handleLinkingVendor (vendor) {
      this.isMappingVendor = false
      this.copyVendors = this.vendorList
      const param = {
        row: {
          ...vendor
        }
      }
      this.removeDuplicateVendors()
      if (vendor.mappedTo.action === 'add') {
        this.handleDeclareNewvendor(param)
      } else {
        this.removeDeclaredVendor(param)
      }

    },
    applyFilters (data) {
      const { filters } = this
      if (!filters) return data
      let draftData = JSON.parse(JSON.stringify(data))
      if (filters.inputName) {
        draftData = draftData.filter(elm => {
          if (!elm.name) return false
          const name = elm.name.toLowerCase()
          return name.includes(filters.inputName.toLowerCase())
        })
      }
      if (filters.tcf.length === 1) {
        if (filters.tcf[0] === 'In TCF') {
          draftData = draftData.filter(elm => elm.tcfv2_id)
        } else if (filters.tcf[0] === 'Not in TCF') {
          draftData = draftData.filter(elm => !elm.tcfv2_id)
        }
      }
      if (filters.index.length) {
        draftData = draftData.filter(vendor => {
          const indexes = filters.index
          return indexes.includes(this.vendorVigilanceToLetter(vendor.vigilance_rating * 5))
        })
      }
      if (filters.categories.length) {
        draftData = draftData.filter(vendor => {
          const categories = filters.categories
          return categories.includes(vendor.category_name)
        })
      }
      if (filters.countryLaws.length) {
        draftData = draftData.filter(vendor => {
          const laws = filters.countryLaws.map(law => law.toLowerCase())
          return laws.includes(vendor.country_protection.toLowerCase())
        })
      }
      if (filters.vl) {
        draftData = draftData.filter(vendor => vendor.declared_in_vl)
      }
      if (filters.legalBasis) {
        const bases = filters.legalBasis
        if (bases.includes('Only Consent')) {
          draftData = draftData.filter(elm => elm.iabvendor_declare_purposes && !elm.iabvendor_declare_legitimate_interest_purposes)
        }
        if (bases.includes('Only Legitimate Interest')) {
          draftData = draftData.filter(elm => elm.iabvendor_declare_legitimate_interest_purposes && !elm.iabvendor_declare_purposes)
        }
      }
      if (filters.tcf_f_p) {
        const f_p = filters.tcf_f_p
        if (f_p.includes('Only purpose 1 declared on consent')) {
          draftData = draftData.filter(elm => elm.iabvendor_only_declare_purpose_one)
        }
        if (f_p.includes('Purpose 1 NOT declared')) {
          draftData = draftData.filter(elm => elm.iabvendor_do_not_declare_purpose_one)
        }
      }
      if (filters.knowVendors) {
        if (filters.knowVendors.includes('Only known vendors')) {
          draftData = draftData.filter(elm => elm.id === -1)
        }
      }
      this.actualFilteredList = draftData
      return draftData
    },
    fetchItems () {
      const that = this
      const fn = new Promise((resolve, reject) => {
        let items = []
        if (that.activeTable === 'totalDetected') items = this.copyVendors.filter(vendor => vendor.detected_in_requests)
        if (that.activeTable === 'totalDeclared') items = that.draftVendorlist
        // ca fait linverse fixme
        if (that.activeTable === 'nonDeclaredActive') items = that.nonDeclaredDraftVendorList.filter(vendor => vendor.detected_in_requests).filter(vendor => (!vendor.declared_in_vl))
        if (that.activeTable === 'totalDetectedActive') items = that.draftVendorlist.filter(vendor => vendor.detected_in_requests).filter(vendor => (vendor.detected_in_cmp || vendor.declared_in_vl))
        if (that.activeTable === 'declaredNonActive') items = that.draftVendorlist.filter(vendor => !vendor.detected_in_requests).filter(vendor => (vendor.declared_in_vl || vendor.detected_in_cmp))
        resolve({
          success: true,
          data: that.paginate(items),
          pagination: that.pagination
        })
      })
      return fn
    },
    paginate (data) {

      const filteredData = this.applyFilters(JSON.parse(JSON.stringify(data)))
      this.pagination.count = filteredData.length
      this.pagination.limit = 20
      return filteredData.slice((this.pagination.page - 1) * this.pagination.limit, this.pagination.page * this.pagination.limit)
    },
    vendorVigilance (vendor) {
      const riskColor = {
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      }
      const vigilance = this.vendorVigilanceToLetter(vendor.vigilance_rating * 5)

      return {
        vigilance: vigilance,
        color: riskColor[vigilance]
      }
    },
    handleDeclareNewvendor (param) {
      // this.copyVendors.find(vendor => vendor.id === param.row.id).detected_in_cmp = 1
      // this.copyVendors.find(vendor => vendor.id === param.row.id).declared_in_vl = 1
      this.bus.$emit('add-vendor-in-list', [param.row])
      this.handleKpiChange()
    },
    removeDeclaredVendor (param) {
      this.copyVendors = this.copyVendors.filter(vendor => !!vendor)
      this.copyVendors.find(vendor => vendor.id === param.row.id).declared_in_vl = 0
      this.copyVendors.find(vendor => vendor.id === param.row.id).detected_in_cmp = 0
      this.bus.$emit('remove-vendor-in-list', [param.row])
      this.handleKpiChange()
    },
    handleMapUnknownVendor (params, action) {
      params.row.action = action
      this.vendorToMap = params.row
      this.isMappingVendor = true
    },
    customRenderName () {
      return (h, params) => {
        if (params.row.id > 0) {
          return h('a', {
            on: {
              click: () => {
                this.handleDeclareNewvendor(params)
                // this.selectedVendor = params.row
                // this.isOpenDrawer = true
              }}
          },params.row.name)
        }
        else {
          return h('span', {}, params.row.name)
        }

      }
    },
    getVendorCols () {
      return [
        // {
        //   title: 'Newly declared',
        //   width: '140px',
        //   render: (h, params) => {
        //     const isDeclared = params.row.declared_in_vl
        //     return isDeclared
        //       ? h('span', {
        //         style: {
        //           width: '10px',
        //           height: '10px',
        //           display: 'block',
        //           background: '#000',
        //           borderRadius: '10px'
        //         }
        //       }, '')
        //       :''
        //   }
        // },
        // {
        //   title: 'detected in cmp',
        //   key: 'detected_in_cmp'
        // },
        // {
        //   title: 'dectected request',
        //   key: 'detected_in_requests'
        // },
        { title: ' ',
          sortable: false,
          width:'50px',
          render: (h, params) => {
            const notDeclared = !params.row.detected_in_cmp && !params.row.declared_in_vl
            const notDetectedInRequests = !params.row.detected_in_requests
            if (notDetectedInRequests && !notDeclared) {
              return h('i', {
                domProps: {
                  title: "Declared in CMP or Vendor List but not detected in requests"
                },
                class: "fas fa-exclamation-circle text-warning"
              }, '')
            }
            if (notDeclared && !notDetectedInRequests) {
              return h('i', {
                domProps: {
                  title: "Active but not in CMP nor Vendor List"
                },
                class: "fas fa-exclamation-triangle text-error"
              }, '')
            }
          }
        },
        this.createColumn(this.$t('global.table.name'), 'Vendor', 'params.row.name', {type: 'custom', customMethod: this.customRenderName}, false),
        this.createColumn(this.$t('global.table.vigilance'), null, 'vigilance_rating', row => {
          const {vigilance, color} = this.vendorVigilance(row)

          return {
            tag: 'Tag',
            props: {color, type: 'dot'},
            content: vigilance
          }
        }),
        this.vendorCategoryWithHelper(),
        {
          title: 'TCF V2',
          key: 'tcfv2_id'
        },
        this.createColumn(this.$t('global.table.country'), 'country_name', 'country_name', 'underQualification'),
        {
          title: 'Action',
          width: "90px",
          render: (h, params) => {
            return h('Button', {
              props: {
                icon: (params.row.detected_in_cmp || params.row.declared_in_vl) ? 'md-remove' :'md-add',
                type: (params.row.detected_in_cmp || params.row.declared_in_vl) ? 'warning' :'primary'
              },
              on: {
                click: () => {
                  if (params.row.id === -1) {
                    (params.row.detected_in_cmp || params.row.declared_in_vl)
                      ? this.handleMapUnknownVendor(params, 'remove')
                      : this.handleMapUnknownVendor(params, 'add')
                  } else {
                    (params.row.detected_in_cmp || params.row.declared_in_vl) ? this.removeDeclaredVendor(params) : this.handleDeclareNewvendor(params)
                  }
                }
              }
            })
          }
        }
      ]
    },
    vendorCategoryWithHelper () {
      const { showCategoryHelper } = this
      return showCategoryHelper
        ? {
          key: 'Category.name',
          title: this.$t('global.table.category'),
          width: 250,
          render: (h, params) => h('Poptip', {
            props: {
              content:params.row.category.description,
              placement:"right",
              'word-wrap': true,
              style:{wordWrap: 'break-word'},
              width: 300,
              trigger:'hover'
            }
          }, [
            h('Badge', {
              slot:"title",
              props: {
                color: this.complianceColor[this.vendorVigilanceToLetter(params.row.category.vigilance_id)],
                text: this.$t('global.vendor.category_vigilance') + ' ' + this.vendorVigilanceToLetter(params.row.category.vigilance_id) + (params.row.category.is_exempted ? '  - Exempted' : '')
              }
            },),
            // <i class="fas fa-check-double"></i>
            params.row.category && params.row.category.is_exempted
              ? h('span', {},[
                h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}),
                params.row.category_name

              ])
              : h('span', {}, params.row.category_name)
          ])
        }
        : {
          title: this.$t('global.table.category'),
          render: (h, params) => {
            return params.row.category && params.row.category.is_exempted
              ? h('span', {},[
                h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}),
                params.row.category_name

              ])
              : h('span', {}, params.row.category_name)
          }
        }
    },
  }
}
</script>
