<template>
  <Drawer v-model="isDrawerOpen"
          @on-close="$emit('on-close')"
          width="700"
          style="position:absolute"
  >
    <VendorCardContent :vendor-props="vendorProps"
                       :is-open="isOpen"
                       :is-checking-purposes="false"
    />
  </Drawer>
</template>
<script>
import { eventBus } from '@/main'
import VendorCardContent from './VendorCardContent.vue'
export default {
  name: 'VendorDetailDrawer',
  components: { 
    VendorCardContent
  },
  props: {
    isOpen: {
      type: Boolean,
      note: 'is the vendor detail panel open',
      default: false
    },
    vendorProps: {
      type: [Object],
      note: 'vendor object',
      default: null
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler: function (val, _) {
        this.isDrawerOpen = val
      }
    }
  },
  data () {
    return {
      bus: eventBus,
      isDrawerOpen: this.isOpen,
      vendor: null
    }
  },
  computed: {
  },
  methods: {}
    
}
</script>
