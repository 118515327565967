<template>
  <div>
    <Button type="primary"
            size="large"
            style="float:right;"
            @click="openSaveAndExportModal = true"
    >
      Export the Vendor List Project
    </Button>
    <Modal
      draggable
      v-model="openSaveAndExportModal"
      :title="`Export the Vendors List Project`"
      width="640px"
    >
      <SaveVlModal />
      <div slot="footer">
        <Button type="primary"
                style="display:block; margin:0 auto"
                @click="saveAndExportVendorlist"
        >
          Download export files
        </Button>
      </div>
    </Modal>



    <Button type="info"
            size="large"
            style="float:right;"
            @click="helpModal = !helpmodal"
    >
      Guide
    </Button>
    <h1>Vendors list management</h1>
    <br>
    <Modal
      v-model="helpModal"
      class="vlm-help-modal"
      width="640px"
      title="Vendor List Management Guide"
    >
      <HelpModal @close-help="helpModal = false" />
    </Modal>

    <table class="vendorlistactions-table">
      <tr>
        <td :class="(selectedVendorList === 'totalDeclared')?'selected':''">
          <DeclarationVendorWidget ref="totalDeclared"
                                   :is-loading="vendorsPack.totalDeclared.isLoading"
                                   :data="vendorsPack.vendors.filter(vendor => vendor.detected_in_cmp)"
                                   title="Detected in CMP"
                                   title-icon="info"
                                   :font-size="(draftVendorList.length > 0 )?'large':'large'"
                                   @click.native="handleClickWidget('totalDeclared')"
                                   description="Detected vendors in your CMP."
          />
        </td>
        <td rowspan="2"
            :class="(selectedVendorList === 'totalDetected')?'selected':''"
        >
          <DeclarationVendorWidget ref="totalDetected"
                                   :is-loading="vendorsPack.totalDetected.isLoading"
                                   :data="vendorsPack.vendors.filter(vendor => vendor.detected_in_requests === 1)"
                                   title="Active Vendors"
                                   title-icon="info"
                                   :font-size="(draftVendorList.length > 0 )?'x-large':'large'"
                                   @click.native="handleClickWidget('totalDetected')"
                                   description="Vendors with detected activity during the selected period."
          />
        </td>
        <td :class="(selectedVendorList === 'totalDetectedActive')?'selected':''">
          <DeclarationVendorWidget ref="totalDetectedActive"
                                   :is-loading="vendorsPack.totalDetectedActive.isLoading"
                                   :data="vendorsPack.vendors.filter(vendor => vendor.detected_in_cmp && vendor.detected_in_requests)"
                                   title="Active and in CMP"
                                   title-icon="success"
                                   :font-size="(draftVendorList.length > 0 )?'large':'large'"
                                   @click.native="handleClickWidget('totalDetectedActive')"
                                   description="Vendors with detected activity during the selected period and detected in your CMP."
          />
        </td>
        <td :class="(selectedVendorList === 'nonDeclaredActive')?'selected':''">
          <DeclarationVendorWidget ref="nonDeclaredActive"
                                   :is-loading="vendorsPack.nonDeclaredActive.isLoading"
                                   :data="vendorsPack.vendors.filter(vendor => !vendor.detected_in_cmp && vendor.detected_in_requests)"
                                   title="Active but not in CMP"
                                   title-icon="error"
                                   :font-size="(draftVendorList.length > 0 )?'large':'large'"
                                   @click.native="handleClickWidget('nonDeclaredActive')"
                                   description="Vendors with detected activity during the selected period but not detected in your CMP."
          />
        </td>
        <td :class="(selectedVendorList === 'declaredNonActive')?'selected':''">
          <DeclarationVendorWidget ref="declaredNonActive"
                                   :is-loading="vendorsPack.declaredNonActive.isLoading"
                                   :data="vendorsPack.vendors.filter(vendor => vendor.detected_in_cmp && !vendor.detected_in_requests)"
                                   title="Inactive but in CMP"
                                   title-icon="warning"
                                   :font-size="(draftVendorList.length > 0 )?'large':'large'"
                                   description="Vendors without any detected activity for the selected period but detected in you CMP."
                                   @click.native="handleClickWidget('declaredNonActive')"
          />
        </td>
      </tr>
      <tr :class="(draftVendorList.length > 0)?'':'hidden'">
        <td :class="(selectedVendorList === 'totalDeclared')?'selected':''">
          <DeclarationVendorWidget :next-widget="true"
                                   :data="draftVendorList"
                                   :base-data="vendorsPack.vendors.filter(vendor => vendor.detected_in_cmp)"
                                   :more-is-better="true"
                                   ref-name="totalDeclaredNext"
                                   title="Declared in vendor list project"
                                   font-size="large"
                                   @click.native="handleClickWidget('totalDeclared')"
          />
        </td>
        <td :class="(selectedVendorList === 'totalDetectedActive')?'selected':''">
          <DeclarationVendorWidget :next-widget="true"
                                   :data="draftVendorList.filter(vendor => vendor.detected_in_requests).filter(vendor => (vendor.declared_in_vl || vendor.detected_in_cmp))"
                                   :base-data="vendorsPack.vendors.filter(vendor => vendor.detected_in_cmp && vendor.detected_in_requests)"
                                   :more-is-better="true"
                                   ref-name="totalDetectedActiveNext"
                                   title="Active and declared in vendor list project"
                                   font-size="large"
                                   @click.native="handleClickWidget('totalDetectedActive')"
          />
        </td>
        <td :class="(selectedVendorList === 'nonDeclaredActive')?'selected':''">
          <DeclarationVendorWidget :next-widget="true"
                                   :data="nonDeclaredDraftVendorList.filter(vendor => vendor.detected_in_requests).filter(vendor => (!vendor.declared_in_vl))"
                                   :base-data="vendorsPack.vendors.filter(vendor => !vendor.detected_in_cmp && vendor.detected_in_requests)"
                                   :more-is-better="false"
                                   ref-name="nonDeclaredActiveNext"
                                   title="Active but not declared in vendor list project"
                                   font-size="large"
                                   @click.native="handleClickWidget('nonDeclaredActive')"
          />
        </td>
        <td :class="(selectedVendorList === 'declaredNonActive')?'selected':''">
          <DeclarationVendorWidget :next-widget="true"
                                   ref-name="declaredNonActiveNext"
                                   title="Inactive but declared in vendor list project"
                                   font-size="large"
                                   :data="draftVendorList.filter(vendor => !vendor.detected_in_requests).filter(vendor => (vendor.declared_in_vl || vendor.detected_in_cmp))"
                                   :base-data="vendorsPack.vendors.filter(vendor => vendor.detected_in_cmp && !vendor.detected_in_requests)"
                                   :more-is-better="false"
                                   @click.native="handleClickWidget('declaredNonActive')"
          />
        </td>
      </tr>
      <tr>
        <td colspan="5">
          <Card v-if="selectedVendorList === 'totalDeclared'"
                :bordered="false"
                :dis-hover="true"
          >
            <br>
            <h1>Detected vendors in your CMP</h1>
            <br>
            <Row>
              <Col :md="16"
                   :sm="24"
              >
                <div>
                  <p>In order to inform your visitors, all vendors on your website should be declared in your CMP (Consent Management Platform).</p>
                  <br>
                  <p>On rare occasions, the full list of vendors is not available nor accessible. This is the case when : </p>
                  <ul>
                    <li>&nbsp;- technical restriction are implemented such as antibot technology</li>
                    <li>&nbsp;- selective geographical (ip-based) content does not expose CMP</li>
                    <li>&nbsp;- the CMP is not providing the list under 2 user actions</li>
                    <li>&nbsp;- the CMP is not TCFv2 compatible</li>
                  </ul>
                </div>
              </Col>
              <Col :md="8"
                   :sm="24"
              >
                <div />
              </Col>
            </Row>
          </Card>
          <Card v-if="selectedVendorList === 'totalDetected'"
                :bordered="false"
                :dis-hover="true"
          >
            <br>
            <h1>Vendors with detected activity during the selected period</h1>
            <br>
            <Row>
              <Col :md="16"
                   :sm="24"
              >
                <div>
                  <p>The number of active vendors may vary depending on your website partner integrations.</p>
                  <br>
                  <p>Review all active vendors to confirm you have an understanding of their activity.</p>
                  <p>
                    In the case of legitimate vendors, declare them on your Vendor List using the
                    <button type="button"
                            class="ivu-btn ivu-btn-primary ivu-btn-icon-only"
                    >
                      <i class="ivu-icon ivu-icon-md-add" />
                    </button>
                    button.
                  </p>
                  <br>
                  <p>
                    For any other cases, please reach our customer support to help you find the best solution at <a href="mailto:support@agnostik.io">support@agnostik.io</a>
                  </p>
                </div>
              </Col>
              <Col :md="8"
                   :sm="24"
              >
                <div style="text-align: right">
                  <br>
                  <Button type="primary"
                          size="large"
                          @click="showDeclareAllActiveTCFVendorsInVendorlistProjectModal = true"
                  >
                    Declare all active TCF vendors to Vendor List
                  </Button>
                  <Modal
                    v-model="showDeclareAllActiveTCFVendorsInVendorlistProjectModal"
                    width="640px"
                    title="Declare all active TCF vendors in Vendor List Project"
                    @on-ok="declareAllActiveTCFVendorsInVendorlistProject"
                    @on-cancel="showDeclareAllActiveTCFVendorsInVendorlistProjectModal = false"
                  >
                    <p>This feature will add all TCF vendors that we identified as active into your vendor list project.</p>
                    <p>Each vendor Purposes and Features will be set to their default TCF settings.</p>
                    <p>After this procedure you will be redirected to the "Active but not declared in this vendor list project" section to manage the remaining vendors.</p>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Card>
          <Card v-if="selectedVendorList === 'totalDetectedActive'"
                :bordered="false"
                :dis-hover="true"
          >
            <br>
            <h1>Vendors with detected activity during the selected period and detected in your CMP</h1>
            <br>
            <Row>
              <Col :md="16"
                   :sm="24"
              >
                <div>
                  <p>In order to inform your visitors, all vendors on your website should be declared in your CMP (Consent Management Platform).</p>
                  <p>By presenting a comprehensive list of your partners, you allow your visitors to make an informed choice regarding the purposes and features requested by these vendors.</p>
                </div>
              </Col>
              <Col :md="8"
                   :sm="24"
              >
                <div />
              </Col>
            </Row>
          </Card>
          <Card v-if="selectedVendorList === 'nonDeclaredActive'"
                :bordered="false"
                :dis-hover="true"
          >
            <br>
            <h1>Vendors with detected activity during the selected period but not detected in your CMP</h1>
            <br>
            <Row>
              <Col :md="16"
                   :sm="24"
              >
                <div>
                  <p>Having active vendors undeclared in your CMP happens quite often as they can have legitimate reasons such as : </p>
                  <ul>
                    <li>&nbsp; - the vendors are part of your organization but are hosted on different domains as can happen with centralized authentication. This should not be a cause for concern.</li>
                    <li>&nbsp; - the vendors are technical enablers that do not leverage trackers or data.</li>
                    <li>&nbsp; - the vendors are confirmed legitimate and exempt from consent. That applies to vendors solutions and implementations whitelisted by your local DPA.</li>
                  </ul>
                  <br>
                  <p>In any other case, you may be at risk of a privacy breach by not declaring them. We suggest you add them to you Vendor List project after confirming their presence is legitimate.</p>
                </div>
              </Col>
              <Col :md="8"
                   :sm="24"
              >
                <div />
              </Col>
            </Row>
          </Card>
          <Card v-if="selectedVendorList === 'declaredNonActive'"
                :bordered="false"
                :dis-hover="true"
          >
            <br>
            <h1>Vendors without any detected activity for the selected period but detected in you CMP</h1>
            <br>
            <Row>
              <Col :md="16"
                   :sm="24"
              >
                <div>
                  <p>Declaring too many inactive vendors in you CMP is often unnecessary.</p>
                  <br>
                  <p>Depending on the business model of you website you may keep them for new or upcoming vendors that you will activate.</p>
                  <p>This is usually the case when implementing "open auction" solutions which rely on many unknown vendors.</p>
                  <br>
                  <p>
                    We would recommend to keep this section at a minimum and remove non-vetted vendors from your Vendor List by using the
                    <button type="button"
                            class="ivu-btn ivu-btn-warning ivu-btn-icon-only"
                    >
                      <i class="ivu-icon ivu-icon-md-remove" />
                    </button>
                    button
                  </p>
                </div>
              </Col>
              <Col :md="8"
                   :sm="24"
              >
                <div />
              </Col>
            </Row>
          </Card>
        </td>
      </tr>
    </table>


    <Drawer
      v-if="!!clickedVendor"
      v-model="purposeModal"
      width="700"
      @on-ok="handleAddVendor"
      :title="`Select Purposes and Features for this vendor`"
    >
      <VendorCardContent :vendor-props="clickedVendor"
                         ref="TCF-vendor-card"
                         :is-open="purposeModal"
                         style="min-height:calc(100% - 80px);"
      />
      <div class="drawer-footer">
        <div v-if="clickedVendor.declared_in_vl || clickedVendor.detected_in_cmp">
          <!--        clickedVendor.declared_in_vl : {{ clickedVendor.declared_in_vl }}-->
          <!--          <Button type="warning"-->
          <!--                  style="float:left"-->
          <!--                  @click="handleRemoveVendor(clickedVendor)"-->
          <!--          >-->
          <!--            Remove from Vendors List-->
          <!--          </Button>-->
          <Button type="primary"
                  @click="handleAddVendor"
          >
            Update Vendors List
          </Button>
        </div>
        <div v-else>
          <Button type="primary"
                  @click="handleAddVendor"
          >
            Add Vendor to Vendors List
          </Button>
        </div>
      </div>
    </Drawer>
  </div>
</template>
<script>
import {TCFV2} from '@/config'
import DeclarationVendorWidget from './DeclarationVendorWidget'
import VendorCardContent from './VendorCardContent.vue'
import SaveVlModal from './SaveVlModal.vue'
import HelpModal from './HelpModal.vue'
import { eventBus } from '@/main'
import moment from 'moment'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {EXEMPTED_RADIO_LIST} from "@/views/Cookies/CookiePolicy/constants"
import {getCookieAgeInMonthOrDays} from "@/helpers"
export default {
  name: "VendorListAction",
  components: {
    DeclarationVendorWidget,
    VendorCardContent,
    SaveVlModal,
    HelpModal
  },
  data () {
    return {
      bus: eventBus,
      selectedVendorList: 'totalDetected',
      clickedVendor: null,
      purposeModal: false,
      helpModal: false,
      showDeclareAllActiveTCFVendorsInVendorlistProjectModal: false,
      moment: moment,
      openSaveAndExportModal: false,
      vendorsPack: {
        vendors: [],
        totalDetected: {
          isLoading: true
        },
        nonDeclaredActive: {
          isLoading: true
        },
        totalDeclared: {
          isLoading: true
        },
        totalDetectedActive: {
          isLoading: true
        },
        declaredNonActive: {
          isLoading: true
        }
      },
      vendorVlModel: {
        name: "",
        category_name: "",
        country_name: "",
        country_protection: "",
        iabvendor_active: null,
        iabvendor_declare_flexible_purposes: null,
        iabvendor_declare_legitimate_interest_purposes: null,
        iabvendor_tcfv2_f_and_p: null,
        id: -1,
        tcfv2_id: null,
        uuid: "",
        vigilance_rating:0,
      },
      default_tcfv2_f_and_p: TCFV2.FEATURES_AND_PURPOSES,
    }
  },
  mounted () {
    this.bus.$on('add-vendor-in-list', (vendors) => {
      const vendor = vendors[0]
      this.clickedVendor = vendor
      this.purposeModal = true
    })
    this.bus.$on('remove-vendor-in-list', (vendors) => {
      vendors.forEach(vendor => {
        const data = {
          properties: [this.property.id],
          vendor_id: vendor.id || null
        }
        vendor.id && this.removeVendorFromVendorlist({data})
          .then(r => {
            if (!r.success) {
              return this.$message.warning('Error please contact the support')
            }else{
              this.removeVendor(vendor)
              this.bus.$emit('trigger-filter', null)
              this.$Notice.open({
                title: `${vendor.name} has been removed from the vendor list`
              })
            }
          })
      })
    })
    this.qs && this.fetchVendors()
  },
  watch: {
    qs: {
      handler: function() {
        this.vendorsPack.totalDetected.isLoading = true
        this.vendorsPack.nonDeclaredActive.isLoading = true
        this.vendorsPack.totalDetectedActive.isLoading = true
        this.vendorsPack.declaredNonActive.isLoading = true
        this.vendorsPack.totalDeclared.isLoading = true
        this.setVendors([])
        this.vendorsPack.vendors = []
        this.bus.$emit('fetched-vendor', this.vendorsPack)
        this.bus.$emit('trigger-filter', false)
        return this.fetchVendors()
      }
    }
  },
  computed: {
    ...mapGetters({
      property: 'scope/selectedProperty',
      qs: 'scope/qs',
      period: 'scope/selectedDaterange',
      draftVendorList: 'vendor/draftVendorList',
      nonDeclaredDraftVendorList: 'vendor/nonDeclaredDraftVendorList',
      userHasRole: 'user/hasRole',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      vendorList: 'vendor/vendorList',
    })
  },
  methods: {
    ...mapActions({
      getVendorlist: 'vendor/getVendorlist',
      getVendorObservedVendorList: 'vendor/getVendorObservedVendorList',
      getVendorDeclaredVendors: 'vendor/getVendorDeclaredVendors',
      postVendorToVendorlist: 'vendor/postVendorToVendorlist',
      removeVendorFromVendorlist: 'vendor/removeVendorFromVendorlist'
    }),
    ...mapMutations({
      addVendor: 'vendor/PUSH_TO_DRAFT_VENDORLIST',
      addNewVendor: 'vendor/PUSH_NEW_VENDOR_TO_DRAFT_VENDORLIST',
      removeVendor: 'vendor/REMOVE_FROM_DRAFT_VENDORLIST',
      setVendors: 'vendor/SET_DRAFT_VENDORLIST',
      setDeclaredVendors: 'vendor/SET_DECLARED_VENDORS'
    }),
    async declareAllActiveTCFVendorsInVendorlistProject(){
      // console.log(this.vendorList.filter(vendor => vendor.detected_in_requests == 1 && vendor.tcfv2_id))

      for (let vendor of this.vendorList){
      // meh this is a clone, not the direct set so we cannot modify these directly
      // for (let vendor of this.vendorList.filter(vendor => vendor.detected_in_requests == 1 && vendor.tcfv2_id)){
      // this.vendorList.filter(vendor => vendor.detected_in_requests == 1 && vendor.tcfv2_id).forEach(vendor => {
        if (vendor.detected_in_requests == 1 && vendor.tcfv2_id) {
          let isNewVendor = false
          const data = {
            properties: [this.property.id],
            vendor_id: vendor.id,
            tcfv2_f_and_p: vendor.iabvendor_tcfv2_f_and_p,
            host: vendor.host,
            alias: vendor.alias
          }
          if (!vendor.hasOwnProperty('detected_in_cmp')) {
            vendor = this.createVlVendorObject(vendor)
            isNewVendor = true
          }
          // console.log(data)
          await this.postVendorToVendorlist({data})
            .then(r => {
              if (r.success) {
                if (vendor.id) data.vendor_id = vendor.id
                if (!vendor.id && vendor.host) data.host = vendor.host
                if (!vendor.id && vendor.alias) data.alias = vendor.alias
                // @todo: Changer reponse pour avoir le même formatt que le GET
                if (isNewVendor) {
                  // console.log('add new vendor')
                  this.addNewVendor(vendor)
                }
                // console.log('add vendor')
                this.addVendor(vendor)
                // this.bus.$emit('fetched-vendor')
                this.$Notice.open({
                  title: `${vendor.name} added to the vendor list`
                })

              } else {
                this.$message.warning('Error please contact the support')
              }
            })
        }
      }
      //TODO : this is not super effective but the bus events seems to be half integrated
      this.showDeclareAllActiveTCFVendorsInVendorlistProjectModal = false
      this.handleClickWidget('nonDeclaredActive')
      this.fetchVendors()

      // this.bus.$emit('trigger-filter', false)

    },
    saveAndExportVendorlist () {
      const { draftVendorList, property } = this
      /*
      for(let i =0; i < draftVendorList.length; i++){
        if(draftVendorList[i].tcfv2_f_and_p.purposes.length == 0){
          draftVendorList[i].tcfv2_f_and_p.purposes.push(1)
        }
      }
      */
      const jsonObject = JSON.stringify(draftVendorList.map(vendor => {
        delete vendor.id
        delete vendor.uuid
        if(vendor.vigilance_rating){
          vendor.vigilance_index = this.vendorVigilanceToLetter(vendor.vigilance_rating * 5)
        }
        if(!vendor.tcfv2_f_and_p){
          if(vendor.iabvendor_tcfv2_f_and_p){
            vendor.tcfv2_f_and_p = vendor.iabvendor_tcfv2_f_and_p
          }else{
            // console.log(vendor.name)
            vendor.tcfv2_f_and_p = {
              purposes: [],
              specialPurposes: [],
              legIntPurposes: [],
              flexiblePurposes: [],
              features: [],
              specialFeatures: []
            }
          }
        }
        if(vendor.tcfv2_f_and_p.purposes.length == 0){
          vendor.tcfv2_f_and_p.purposes.push(1)
        }
        return vendor
      }))

      let didomiCustomJSON = {
        "app": {
          "vendors": {
            "didomi":
            /*
              From : https://support.didomi.io/fr/bloquer-les-tags-dans-google-tag-manager-gtm-avec-lint%C3%A9gration-didomi
              ⚠️ Attention, il y a un cas particulier pour 4 vendors.
              Pour Facebook, Google Advertising Product, Amazon et Twitter, il vous faut ajouter un préfixe “didomi:” avant l’ID donné dans la dernière colonne.
              Les IDs corrects sont donc :
              Facebook : “didomi:facebook”
              Amazon : “didomi:amazon”
              Twitter : “didomi:twitter”
              Google Advertising Product : “didomi:google” (vous n'en aurez pas besoin si vous utilisez notre intégration TCF car Google Advertising Products est un vendor IAB)

              => as of 2022-12-01 we exclude Google from the didomi specific cases as they are registered in TCF (id 755)
              => Amazon is also excluded as they usually declare as Sizmek in TCF (id 68) or amazon advertising TCF (793) and the corresponding "amazon" didomi partner does not provide meaningful info
              => therefore, those two vendors will appear in the "iab" section if detected
             */
              [...new Set(draftVendorList?.reduce( (filtered, entry) => {
                !entry.tcfv2_id && entry.name.toLowerCase().includes('twitter') && filtered.push("twitter")
                !entry.tcfv2_id && entry.name.toLowerCase().includes('facebook') && filtered.push("facebook")
                return filtered
              }, []))],
            "iab": {
              "version" : 2,
              "include":
                [...new Set(draftVendorList?.reduce( (filtered, entry) => {
                  !!entry.tcfv2_id && filtered.push(entry.tcfv2_id)
                  return filtered
                }, []))]
            },
            "custom":
            /*
              [
                {
                  “id”: “<name>“, //you can use the same value as for the name of the vendor
                  “name”: “<name>“, //name field from the exported json where the vendor is not tcf
                  “purposeIds”:[<purpose_value1>, <purpose_value2>], // assign to your vendor one or more of the following values: ‘cookies’,‘advertising_personalization’, ‘content_personalization’, ‘ad_delivery’, ‘analytics’
                  “policyUrl”: “http://www.vendor.com/privacy-policy” // the policy url of the vendor
                }
              ]
            */
                [...new Set(draftVendorList?.reduce( (filtered, entry) => {
                  !entry.tcfv2_id
                  && !!entry.name
                  && !entry.name.toLowerCase().includes('twitter')
                  && !entry.name.toLowerCase().includes('facebook')
                  && filtered.push({
                    "id": entry.name,
                    "name": entry.name,
                    "policyUrl": entry.privacy_policy_url || entry.website || '',
                    // TODO : check that as the console seems to not process those correctly
                    "purposeIds":
                        [
                          ...new Set(entry.tcfv2_f_and_p?.purposes?.reduce( (filtered, purposeId) => {
                            for (const didomiPurposeId of this.default_tcfv2_f_and_p.purposes[purposeId].didomiPurposeIds) {
                              filtered.push(didomiPurposeId)
                            }
                            !filtered.length && filtered.push('cookies') // add purpose "cookies" if no purpose declared
                            return filtered
                          }, [])) ,
                          ...new Set(entry.tcfv2_f_and_p?.specialFeatures?.reduce( (filtered, featureId) => {
                            for (const didomiFeatureId of this.default_tcfv2_f_and_p.specialFeatures[featureId].didomiFeatureIds) {
                              filtered.push(didomiFeatureId)
                            }
                            return filtered
                          }, []))
                        ]
                  })
                  return filtered
                }, []))]

          }
        }
      }

      // didomi custom json
      const exportedDidomiCustomJSONFileName = 'didomi-custom-json_'+ property.displayName + '_' + moment().format('LL') + '_' + 'vendorlist.JSON'
      var didomiCustomJSONBlob = new Blob([JSON.stringify(didomiCustomJSON)], { type: 'application/json' })
      var didomiCustomJSONLink = document.createElement("a")
      if (didomiCustomJSONLink.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var didomiCustomJSONUrl = URL.createObjectURL(didomiCustomJSONBlob)
        didomiCustomJSONLink.setAttribute("href", didomiCustomJSONUrl)
        didomiCustomJSONLink.setAttribute("download", exportedDidomiCustomJSONFileName)
        didomiCustomJSONLink.style.visibility = 'hidden'
        document.body.appendChild(didomiCustomJSONLink)
        didomiCustomJSONLink.click()
        document.body.removeChild(didomiCustomJSONLink)
      }

      // default json
      const exportedJSONFileName = property.displayName + '_' + moment().format('LL') + '_' + 'vendorlist.JSON'
      var blob = new Blob([jsonObject], { type: 'application/json' })
      var link = document.createElement("a")
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute("href", url)
        link.setAttribute("download", exportedJSONFileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      this.convertToCsv(JSON.parse(jsonObject), (property.displayName + '_' + moment().format('LL') + '_vendorlist'))
      this.openSaveAndExportModal = false
    },
    convertToCsv (items, name) {
      const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
      // flatten f&p
      items.map(obj => {
        if (obj.tcfv2_f_and_p){
          for (const [key, val] of Object.entries(obj.tcfv2_f_and_p)){
            obj[key] = val.join(';')
          }
        }
        // if(obj.vigilance_rating){
        //   obj.vigilance_index = this.vendorVigilanceToLetter(obj.vigilance_rating * 5)
        // }

      })

      const targetModel = {
        name: "",
        website: "",
        privacy_policy_url: "",
        category_name: "",
        country_name: "",
        country_protection: "",
        iabvendor_active: null,
        iabvendor_declare_flexible_purposes: null,
        iabvendor_declare_legitimate_interest_purposes: null,
        purposes : "",
        flexiblePurposes : "",
        legIntPurposes : "",
        specialPurposes : "",
        features : "",
        specialFeatures : "",
        id: -1,
        tcfv2_id: null,
        uuid: "",
        vigilance_index: "",
      }

      // const header = Object.keys(this.vendorVlModel)
      const header = Object.keys(targetModel)
      const csv = [
        header.join(','), // header row first
        ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
      ].join('\r\n')
      var exportedFilename = name + '.csv' || 'export.csv'
      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilename)
      } else {
        var link = document.createElement("a")
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute("href", url)
          link.setAttribute("download", exportedFilename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },
    handleClickWidget (widgetRef) {
      this.selectedVendorList = widgetRef
      this.$emit('handleWidgetClick', widgetRef)
    },
    // handleRemoveVendor (vendor) {
    //   console.log(vendor)
    //   this.bus.$emit('remove-vendor-in-list', [vendor])
    // },
    handleAddVendor () {
      let vendor = this.clickedVendor
      let isNewVendor = false
      const vendorCardData = this.$refs['TCF-vendor-card']

      if(vendorCardData.tcfv2_f_and_p.purposes.length == 0){
        vendorCardData.tcfv2_f_and_p.purposes.push(1)
      }
      const data = {
        properties:[this.property.id],
        vendor_id: vendor.id,
        tcfv2_f_and_p : vendorCardData.tcfv2_f_and_p,
        host: vendor.host,
        alias: vendor.alias
      }
      if (!vendor.hasOwnProperty('detected_in_cmp')
          && !vendor.hasOwnProperty('detected_in_vl')
      ) {
        vendor = this.createVlVendorObject(vendor)
        isNewVendor = true
      }
      this.postVendorToVendorlist({data})
        .then(r => {
          if (r.success) {
            if (vendor.id) data.vendor_id = vendor.id
            if (!vendor.id && vendor.host) data.host = vendor.host
            if (!vendor.id && vendor.alias) data.alias = vendor.alias
            // @todo: Changer reponse pour avoir le même formatt que le GET
            if (isNewVendor) {
              this.addNewVendor(vendor)
            }
            this.addVendor(vendor)
            this.fetchVendors()
            this.purposeModal = false
            this.$Notice.open({
              title: `${vendor.name} added to the vendor list`
            })
            this.bus.$emit('trigger-filter', null)
          } else {
            this.$message.warning('Error please contact the support')
          }
        })

    },
    createVlVendorObject (vendor) {
      const base = JSON.parse(JSON.stringify(this.vendorVlModel))
      base.country_name = vendor.country ? vendor.country.name : 'Undefined'
      base.country_protection =  vendor.country ? vendor.country.protection : 'Undefined'
      base.category_name = vendor.category ? vendor.category.name : 'N/A'
      base.declared_in_vl = 1
      base.detected_in_cmp = 0
      base.detected_in_requests = 0 // todo : do a double check
      base.iabvendor_active = !!vendor.iabv2Id
      base.id = vendor.id
      base.name = vendor.name
      base.privacy_policy_url = vendor.privacy_policy_url
      base.website = vendor.website
      base.tcfv2_id = vendor.iabv2Id
      base.uuid = vendor.uuid
      base.vigilance_rating = vendor.vigilance_rating
      return base
    },
    fetchVendors () {
      this.getVendorObservedVendorList({
        properties: [this.property.id],
        period: this.period
      }).then(observedResp => {
        this.getVendorDeclaredVendors({
          properties: [this.property.id],
          period: this.period
        }).then(declaredResp => {
          this.setDeclaredVendors(declaredResp.data)
          let observedVendors = observedResp.data
          const declaredVendors = declaredResp.data
          declaredVendors.forEach(vendor => {
            // add declared vendors to the observed vendor list
            if (!observedVendors.find(elm => elm.id === vendor.id)) {
              observedVendors.push(vendor)
            }
            // remove null values from hosts or aliases
            vendor.hosts = vendor.hosts.filter(elm => elm)
            vendor.aliases = vendor.aliases.filter(elm => elm)
            // hide unmapped vendors from observed vendors wich are manually mapped with alias or host
            if (vendor.hosts.length) {
              let mappedByHostVendors = observedVendors.filter(elm => vendor.hosts.includes(elm.host))
              mappedByHostVendors = mappedByHostVendors.map(elm => elm.duplicate = true)
            }
            if (vendor.aliases.length) {
              let mappedByHostVendors = observedVendors.filter(elm => vendor.aliases.includes(elm.alias))
              mappedByHostVendors = mappedByHostVendors.map(elm => elm.duplicate = true)
            }
          })

          //@TODO: trying to get the obsserved vendors that exist on the declared vendors.
          observedVendors = observedVendors.map(vendor => {
            const isDeclared = declaredVendors.find(elm => (vendor.id === elm.id))
            const UnknowName = vendor.host || vendor.alias
            if (!vendor.id) {
              const vnd = Object.assign(vendor, this.vendorVlModel)
              vnd.name = UnknowName
              return vnd
            }
            // declared means, if u can find this particular vendorId in the declared array.
            if (isDeclared) {
              return Object.assign(vendor, isDeclared)
            }
            return vendor
          })


          if (!this.userHasRole('super-admin')) {
            observedVendors = observedVendors.filter(elm => elm.id > 0)
          }

          this.vendorsPack.vendors = observedVendors
          this.vendorsPack.totalDetected.isLoading = false
          this.vendorsPack.nonDeclaredActive.isLoading = false
          this.vendorsPack.totalDetectedActive.isLoading = false
          this.vendorsPack.declaredNonActive.isLoading = false
          this.vendorsPack.totalDeclared.isLoading = false
          this.setVendors(observedVendors)
          this.bus.$emit('fetched-vendor', this.vendorsPack)
        })
        // remove all not mapped vendors
        // r.data = r.data.filter(elm => elm.id)

      })
    }
  }
}
</script>
<style lang="scss">

.drawer-footer{
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  &:before{
    display:table;
    clear:both;
    content:'';
  }
}

button ~ button{
  margin-right: 16px;
}
table.vendorlistactions-table {
  border-spacing: 12px 0px;
  border-collapse: separate;
  transition: all 0.25s;
  width: calc(100% + 24px);
  margin-left:-12px;
  height: 100px;
  table-layout:fixed;
  tr{
    &:first-of-type{
      td{
        border-radius: 16px 16px 0 0 ;
        & > div{
          border-bottom : 1px solid rgba(0,0,0,0.04)
        }
      }
    }
    &.hidden{
      td > *{
        overflow:hidden;
        max-height:0;
        height:100%;
      }
    }
    td{
      vertical-align:top;
      &.selected{
        background: #fff;
        color: rgb(28, 160, 141);
        width:20%;
        transition: all 0.25s;

      }
      &:not(.selected){
        background: rgba(0,0,0,0.02);
        width:20%;
        transition: all 0.25s;
      }
      & > *{
        max-height:600px;
        height:100%;
      }
    }
  }

}
</style>
