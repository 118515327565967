var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.qs ? _c('main', [_c('VendorListActions', {
    on: {
      "handleWidgetClick": function handleWidgetClick(val) {
        return _vm.handleWidgetClick(val);
      }
    }
  }), _c('VendorListTable', {
    attrs: {
      "active-table": _vm.activeTable
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }