var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.triggerTableUpdate ? _c('FilterTable', {
    ref: "vendorListTable",
    attrs: {
      "columns": _vm.vendorCols,
      "fetch-items": _vm.fetchItems,
      "trigger": _vm.triggerTableRefresh,
      "show-columns-selector": false,
      "allow-download": false,
      "limit": _vm.pagination.limit,
      "count": true,
      "is-vendor-list-filters": true,
      "show-title": false,
      "container": true,
      "is-table-loading": _vm.vendorsPack[_vm.activeTable].isLoading,
      "pagination-ref": 'vendor-list'
    }
  }) : _vm._e(), _c('VendorDetailDrawer', {
    attrs: {
      "is-open": _vm.isOpenDrawer,
      "vendor-props": _vm.selectedVendor
    },
    on: {
      "on-close": function onClose($event) {
        _vm.isOpenDrawer = false;
      }
    }
  }), !!_vm.vendorToMap ? _c('Modal', {
    attrs: {
      "draggable": "",
      "title": "Unknown vendor",
      "footer-hide": "",
      "width": "286px"
    },
    model: {
      value: _vm.isMappingVendor,
      callback: function callback($$v) {
        _vm.isMappingVendor = $$v;
      },
      expression: "isMappingVendor"
    }
  }, [_c('ManualMapVendor', {
    ref: "mapping-vendor",
    attrs: {
      "vendor-props": _vm.vendorToMap,
      "is-open": _vm.isMappingVendor
    },
    on: {
      "vendor-linked": _vm.handleLinkingVendor
    }
  }), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }