var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Row', {
    staticClass: "save-vendor-modal",
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "type": "flex"
    }
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('span', [_vm._v("Here are the changes you've made to your vendor list on Facettes")])]), _c('Col', {
    staticClass: "box",
    attrs: {
      "span": "24"
    }
  }, [_vm._v(" " + _vm._s(_vm.draftVendorList.filter(function (elm) {
    return elm.declared_in_vl;
  }).length) + " New Declared Vendors ")]), _c('Col', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('span', [_vm._v("This new list has")])]), _c('Col', {
    staticClass: "box",
    attrs: {
      "span": "24"
    }
  }, [_vm._v(" " + _vm._s(_vm.draftVendorList.length) + " Declared Vendors ")])], 1), _c('br'), _c('div', {
    on: {
      "click": function click($event) {
        _vm.showNoPurposeDraftVendorListVendors = !_vm.showNoPurposeDraftVendorListVendors;
      }
    }
  }, [_vm.noPurposeDraftVendorListVendors.length ? _c('Alert', {
    attrs: {
      "type": "error",
      "show-icon": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.noPurposeDraftVendorListVendors.length) + " vendor(s) in you list have no declared purposes and will be assigned the purpose \"1 - Store and/or access information on a device\" or \"cookies\" by default for exports "), _vm.showNoPurposeDraftVendorListVendors ? _c('div', [_c('br'), _vm._l(_vm.noPurposeDraftVendorListVendors, function (item, index) {
    return _c('p', {
      key: 'noPurposeDraftVendorListVendors' + index
    }, [_vm._v(" - " + _vm._s(item.name) + " ")]);
  })], 2) : _vm._e()]) : _vm._e()], 1), _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" For your changes to be reflected live on your properties, make sure to import the downloaded JSON/CSV file to your CMP. ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }