var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.vendorProps ? _c('Row', {
    staticClass: "map-vendor-poptip",
    attrs: {
      "justify": "space-around"
    }
  }, [!_vm.selectedVendor ? _c('Row', [_c('div', {
    staticStyle: {
      "text-align": "center",
      "width": "100%"
    }
  }, [_c('p', [_vm._v("This vendor")]), _c('br'), _c('p', [_c('strong', [_vm._v(_vm._s(_vm.ellipsis(_vm.vendorProps.alias) || _vm.ellipsis(_vm.vendorProps.host)) + " ")])]), _c('br'), _c('p', [_vm._v("Is unknow")])]), _c('Divider'), _c('div', {
    staticClass: "container-1"
  }, [_c('p', [_vm._v("Suggestion from Agnostik library (" + _vm._s(_vm.suggestVendors.length) + " vendors)")]), _c('br'), _vm.suggestVendors.length ? _c('ul', _vm._l(_vm.suggestVendors, function (val, key) {
    return _c('li', {
      key: key,
      staticClass: "list-item",
      on: {
        "click": function click($event) {
          return _vm.selectVendor(val.vendor);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.ellipsis(val.vendor.name)) + " "), _c('Icon', {
      attrs: {
        "type": "md-add"
      }
    })], 1);
  }), 0) : _vm._e(), _c('br'), _c('br'), _c('p', [_vm._v("Or find another one")]), _c('br'), _c('Input', {
    staticStyle: {
      "width": "100%",
      "display": "block"
    },
    attrs: {
      "type": "text",
      "suffix": "ios-search",
      "placeholder": "Vendor name"
    },
    on: {
      "input": _vm.debounceInput
    },
    model: {
      value: _vm.inputName,
      callback: function callback($$v) {
        _vm.inputName = $$v;
      },
      expression: "inputName"
    }
  }), _vm.searchVendors.length ? _c('ul', _vm._l(_vm.searchVendors, function (val, key) {
    return _c('li', {
      key: key,
      staticClass: "list-item",
      on: {
        "click": function click($event) {
          return _vm.selectVendor(val);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.ellipsis(val.name)) + " "), _c('Icon', {
      attrs: {
        "type": "md-add"
      }
    })], 1);
  }), 0) : _vm._e(), _c('br'), _c('p', [_vm._v(" If you don’t find a matching vendor,"), _c('br'), _vm._v(" contact Agnostik customer support : ")])], 1), _c('a', {
    staticStyle: {
      "display": "block",
      "text-align": "center",
      "width": "100%",
      "margin-top": "24px"
    },
    attrs: {
      "HREF": "mailto:support@agnostik.io"
    }
  }, [_vm._v("support@agnostik.io")])], 1) : _c('Row', [_c('div', {
    staticStyle: {
      "text-align": "center",
      "width": "100%",
      "position": "relative"
    }
  }, [_c('i', {
    staticClass: "fas fa-arrow-left",
    staticStyle: {
      "positon": "aboslute",
      "cursor": "pointer",
      "left": "-45px",
      "top": "-5px",
      "font-size": "14px",
      "font-weight": "bold",
      "position": "absolute"
    },
    on: {
      "click": function click($event) {
        _vm.selectedVendor = !_vm.selectedVendor;
      }
    }
  }), _c('p', [_vm._v("This unknow vendor")]), _c('br'), _c('p', [_c('strong', [_vm._v(_vm._s(_vm.ellipsis(_vm.vendorProps.alias) || _vm.ellipsis(_vm.vendorProps.host)) + " ")])]), _c('br'), _c('p', [_vm._v(" will be linked to")]), _c('br'), _c('p', {
    staticStyle: {
      "text-align": "center",
      "font-weight": "700",
      "padding": "8px",
      "background": "#ebebeb"
    }
  }, [_vm._v(" " + _vm._s(_vm.ellipsis(_vm.selectedVendor.name)) + " ")]), _c('br'), _c('p', [_vm._v("Do you want to confirm ?")]), _c('br'), _c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.linkVendor
    }
  }, [_vm._v(" Confirm ")])], 1)])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }