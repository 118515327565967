var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": function click($event) {
        _vm.openSaveAndExportModal = true;
      }
    }
  }, [_vm._v(" Export the Vendor List Project ")]), _c('Modal', {
    attrs: {
      "draggable": "",
      "title": "Export the Vendors List Project",
      "width": "640px"
    },
    model: {
      value: _vm.openSaveAndExportModal,
      callback: function callback($$v) {
        _vm.openSaveAndExportModal = $$v;
      },
      expression: "openSaveAndExportModal"
    }
  }, [_c('SaveVlModal'), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    staticStyle: {
      "display": "block",
      "margin": "0 auto"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.saveAndExportVendorlist
    }
  }, [_vm._v(" Download export files ")])], 1)], 1), _c('Button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "info",
      "size": "large"
    },
    on: {
      "click": function click($event) {
        _vm.helpModal = !_vm.helpmodal;
      }
    }
  }, [_vm._v(" Guide ")]), _c('h1', [_vm._v("Vendors list management")]), _c('br'), _c('Modal', {
    staticClass: "vlm-help-modal",
    attrs: {
      "width": "640px",
      "title": "Vendor List Management Guide"
    },
    model: {
      value: _vm.helpModal,
      callback: function callback($$v) {
        _vm.helpModal = $$v;
      },
      expression: "helpModal"
    }
  }, [_c('HelpModal', {
    on: {
      "close-help": function closeHelp($event) {
        _vm.helpModal = false;
      }
    }
  })], 1), _c('table', {
    staticClass: "vendorlistactions-table"
  }, [_c('tr', [_c('td', {
    class: _vm.selectedVendorList === 'totalDeclared' ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    ref: "totalDeclared",
    attrs: {
      "is-loading": _vm.vendorsPack.totalDeclared.isLoading,
      "data": _vm.vendorsPack.vendors.filter(function (vendor) {
        return vendor.detected_in_cmp;
      }),
      "title": "Detected in CMP",
      "title-icon": "info",
      "font-size": _vm.draftVendorList.length > 0 ? 'large' : 'large',
      "description": "Detected vendors in your CMP."
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget('totalDeclared');
      }
    }
  })], 1), _c('td', {
    class: _vm.selectedVendorList === 'totalDetected' ? 'selected' : '',
    attrs: {
      "rowspan": "2"
    }
  }, [_c('DeclarationVendorWidget', {
    ref: "totalDetected",
    attrs: {
      "is-loading": _vm.vendorsPack.totalDetected.isLoading,
      "data": _vm.vendorsPack.vendors.filter(function (vendor) {
        return vendor.detected_in_requests === 1;
      }),
      "title": "Active Vendors",
      "title-icon": "info",
      "font-size": _vm.draftVendorList.length > 0 ? 'x-large' : 'large',
      "description": "Vendors with detected activity during the selected period."
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget('totalDetected');
      }
    }
  })], 1), _c('td', {
    class: _vm.selectedVendorList === 'totalDetectedActive' ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    ref: "totalDetectedActive",
    attrs: {
      "is-loading": _vm.vendorsPack.totalDetectedActive.isLoading,
      "data": _vm.vendorsPack.vendors.filter(function (vendor) {
        return vendor.detected_in_cmp && vendor.detected_in_requests;
      }),
      "title": "Active and in CMP",
      "title-icon": "success",
      "font-size": _vm.draftVendorList.length > 0 ? 'large' : 'large',
      "description": "Vendors with detected activity during the selected period and detected in your CMP."
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget('totalDetectedActive');
      }
    }
  })], 1), _c('td', {
    class: _vm.selectedVendorList === 'nonDeclaredActive' ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    ref: "nonDeclaredActive",
    attrs: {
      "is-loading": _vm.vendorsPack.nonDeclaredActive.isLoading,
      "data": _vm.vendorsPack.vendors.filter(function (vendor) {
        return !vendor.detected_in_cmp && vendor.detected_in_requests;
      }),
      "title": "Active but not in CMP",
      "title-icon": "error",
      "font-size": _vm.draftVendorList.length > 0 ? 'large' : 'large',
      "description": "Vendors with detected activity during the selected period but not detected in your CMP."
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget('nonDeclaredActive');
      }
    }
  })], 1), _c('td', {
    class: _vm.selectedVendorList === 'declaredNonActive' ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    ref: "declaredNonActive",
    attrs: {
      "is-loading": _vm.vendorsPack.declaredNonActive.isLoading,
      "data": _vm.vendorsPack.vendors.filter(function (vendor) {
        return vendor.detected_in_cmp && !vendor.detected_in_requests;
      }),
      "title": "Inactive but in CMP",
      "title-icon": "warning",
      "font-size": _vm.draftVendorList.length > 0 ? 'large' : 'large',
      "description": "Vendors without any detected activity for the selected period but detected in you CMP."
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget('declaredNonActive');
      }
    }
  })], 1)]), _c('tr', {
    class: _vm.draftVendorList.length > 0 ? '' : 'hidden'
  }, [_c('td', {
    class: _vm.selectedVendorList === 'totalDeclared' ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    attrs: {
      "next-widget": true,
      "data": _vm.draftVendorList,
      "base-data": _vm.vendorsPack.vendors.filter(function (vendor) {
        return vendor.detected_in_cmp;
      }),
      "more-is-better": true,
      "ref-name": "totalDeclaredNext",
      "title": "Declared in vendor list project",
      "font-size": "large"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget('totalDeclared');
      }
    }
  })], 1), _c('td', {
    class: _vm.selectedVendorList === 'totalDetectedActive' ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    attrs: {
      "next-widget": true,
      "data": _vm.draftVendorList.filter(function (vendor) {
        return vendor.detected_in_requests;
      }).filter(function (vendor) {
        return vendor.declared_in_vl || vendor.detected_in_cmp;
      }),
      "base-data": _vm.vendorsPack.vendors.filter(function (vendor) {
        return vendor.detected_in_cmp && vendor.detected_in_requests;
      }),
      "more-is-better": true,
      "ref-name": "totalDetectedActiveNext",
      "title": "Active and declared in vendor list project",
      "font-size": "large"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget('totalDetectedActive');
      }
    }
  })], 1), _c('td', {
    class: _vm.selectedVendorList === 'nonDeclaredActive' ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    attrs: {
      "next-widget": true,
      "data": _vm.nonDeclaredDraftVendorList.filter(function (vendor) {
        return vendor.detected_in_requests;
      }).filter(function (vendor) {
        return !vendor.declared_in_vl;
      }),
      "base-data": _vm.vendorsPack.vendors.filter(function (vendor) {
        return !vendor.detected_in_cmp && vendor.detected_in_requests;
      }),
      "more-is-better": false,
      "ref-name": "nonDeclaredActiveNext",
      "title": "Active but not declared in vendor list project",
      "font-size": "large"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget('nonDeclaredActive');
      }
    }
  })], 1), _c('td', {
    class: _vm.selectedVendorList === 'declaredNonActive' ? 'selected' : ''
  }, [_c('DeclarationVendorWidget', {
    attrs: {
      "next-widget": true,
      "ref-name": "declaredNonActiveNext",
      "title": "Inactive but declared in vendor list project",
      "font-size": "large",
      "data": _vm.draftVendorList.filter(function (vendor) {
        return !vendor.detected_in_requests;
      }).filter(function (vendor) {
        return vendor.declared_in_vl || vendor.detected_in_cmp;
      }),
      "base-data": _vm.vendorsPack.vendors.filter(function (vendor) {
        return vendor.detected_in_cmp && !vendor.detected_in_requests;
      }),
      "more-is-better": false
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleClickWidget('declaredNonActive');
      }
    }
  })], 1)]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm.selectedVendorList === 'totalDeclared' ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('br'), _c('h1', [_vm._v("Detected vendors in your CMP")]), _c('br'), _c('Row', [_c('Col', {
    attrs: {
      "md": 16,
      "sm": 24
    }
  }, [_c('div', [_c('p', [_vm._v("In order to inform your visitors, all vendors on your website should be declared in your CMP (Consent Management Platform).")]), _c('br'), _c('p', [_vm._v("On rare occasions, the full list of vendors is not available nor accessible. This is the case when : ")]), _c('ul', [_c('li', [_vm._v(" - technical restriction are implemented such as antibot technology")]), _c('li', [_vm._v(" - selective geographical (ip-based) content does not expose CMP")]), _c('li', [_vm._v(" - the CMP is not providing the list under 2 user actions")]), _c('li', [_vm._v(" - the CMP is not TCFv2 compatible")])])])]), _c('Col', {
    attrs: {
      "md": 8,
      "sm": 24
    }
  }, [_c('div')])], 1)], 1) : _vm._e(), _vm.selectedVendorList === 'totalDetected' ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('br'), _c('h1', [_vm._v("Vendors with detected activity during the selected period")]), _c('br'), _c('Row', [_c('Col', {
    attrs: {
      "md": 16,
      "sm": 24
    }
  }, [_c('div', [_c('p', [_vm._v("The number of active vendors may vary depending on your website partner integrations.")]), _c('br'), _c('p', [_vm._v("Review all active vendors to confirm you have an understanding of their activity.")]), _c('p', [_vm._v(" In the case of legitimate vendors, declare them on your Vendor List using the "), _c('button', {
    staticClass: "ivu-btn ivu-btn-primary ivu-btn-icon-only",
    attrs: {
      "type": "button"
    }
  }, [_c('i', {
    staticClass: "ivu-icon ivu-icon-md-add"
  })]), _vm._v(" button. ")]), _c('br'), _c('p', [_vm._v(" For any other cases, please reach our customer support to help you find the best solution at "), _c('a', {
    attrs: {
      "href": "mailto:support@agnostik.io"
    }
  }, [_vm._v("support@agnostik.io")])])])]), _c('Col', {
    attrs: {
      "md": 8,
      "sm": 24
    }
  }, [_c('div', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('br'), _c('Button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": function click($event) {
        _vm.showDeclareAllActiveTCFVendorsInVendorlistProjectModal = true;
      }
    }
  }, [_vm._v(" Declare all active TCF vendors to Vendor List ")]), _c('Modal', {
    attrs: {
      "width": "640px",
      "title": "Declare all active TCF vendors in Vendor List Project"
    },
    on: {
      "on-ok": _vm.declareAllActiveTCFVendorsInVendorlistProject,
      "on-cancel": function onCancel($event) {
        _vm.showDeclareAllActiveTCFVendorsInVendorlistProjectModal = false;
      }
    },
    model: {
      value: _vm.showDeclareAllActiveTCFVendorsInVendorlistProjectModal,
      callback: function callback($$v) {
        _vm.showDeclareAllActiveTCFVendorsInVendorlistProjectModal = $$v;
      },
      expression: "showDeclareAllActiveTCFVendorsInVendorlistProjectModal"
    }
  }, [_c('p', [_vm._v("This feature will add all TCF vendors that we identified as active into your vendor list project.")]), _c('p', [_vm._v("Each vendor Purposes and Features will be set to their default TCF settings.")]), _c('p', [_vm._v("After this procedure you will be redirected to the \"Active but not declared in this vendor list project\" section to manage the remaining vendors.")])])], 1)])], 1)], 1) : _vm._e(), _vm.selectedVendorList === 'totalDetectedActive' ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('br'), _c('h1', [_vm._v("Vendors with detected activity during the selected period and detected in your CMP")]), _c('br'), _c('Row', [_c('Col', {
    attrs: {
      "md": 16,
      "sm": 24
    }
  }, [_c('div', [_c('p', [_vm._v("In order to inform your visitors, all vendors on your website should be declared in your CMP (Consent Management Platform).")]), _c('p', [_vm._v("By presenting a comprehensive list of your partners, you allow your visitors to make an informed choice regarding the purposes and features requested by these vendors.")])])]), _c('Col', {
    attrs: {
      "md": 8,
      "sm": 24
    }
  }, [_c('div')])], 1)], 1) : _vm._e(), _vm.selectedVendorList === 'nonDeclaredActive' ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('br'), _c('h1', [_vm._v("Vendors with detected activity during the selected period but not detected in your CMP")]), _c('br'), _c('Row', [_c('Col', {
    attrs: {
      "md": 16,
      "sm": 24
    }
  }, [_c('div', [_c('p', [_vm._v("Having active vendors undeclared in your CMP happens quite often as they can have legitimate reasons such as : ")]), _c('ul', [_c('li', [_vm._v("  - the vendors are part of your organization but are hosted on different domains as can happen with centralized authentication. This should not be a cause for concern.")]), _c('li', [_vm._v("  - the vendors are technical enablers that do not leverage trackers or data.")]), _c('li', [_vm._v("  - the vendors are confirmed legitimate and exempt from consent. That applies to vendors solutions and implementations whitelisted by your local DPA.")])]), _c('br'), _c('p', [_vm._v("In any other case, you may be at risk of a privacy breach by not declaring them. We suggest you add them to you Vendor List project after confirming their presence is legitimate.")])])]), _c('Col', {
    attrs: {
      "md": 8,
      "sm": 24
    }
  }, [_c('div')])], 1)], 1) : _vm._e(), _vm.selectedVendorList === 'declaredNonActive' ? _c('Card', {
    attrs: {
      "bordered": false,
      "dis-hover": true
    }
  }, [_c('br'), _c('h1', [_vm._v("Vendors without any detected activity for the selected period but detected in you CMP")]), _c('br'), _c('Row', [_c('Col', {
    attrs: {
      "md": 16,
      "sm": 24
    }
  }, [_c('div', [_c('p', [_vm._v("Declaring too many inactive vendors in you CMP is often unnecessary.")]), _c('br'), _c('p', [_vm._v("Depending on the business model of you website you may keep them for new or upcoming vendors that you will activate.")]), _c('p', [_vm._v("This is usually the case when implementing \"open auction\" solutions which rely on many unknown vendors.")]), _c('br'), _c('p', [_vm._v(" We would recommend to keep this section at a minimum and remove non-vetted vendors from your Vendor List by using the "), _c('button', {
    staticClass: "ivu-btn ivu-btn-warning ivu-btn-icon-only",
    attrs: {
      "type": "button"
    }
  }, [_c('i', {
    staticClass: "ivu-icon ivu-icon-md-remove"
  })]), _vm._v(" button ")])])]), _c('Col', {
    attrs: {
      "md": 8,
      "sm": 24
    }
  }, [_c('div')])], 1)], 1) : _vm._e()], 1)])]), !!_vm.clickedVendor ? _c('Drawer', {
    attrs: {
      "width": "700",
      "title": "Select Purposes and Features for this vendor"
    },
    on: {
      "on-ok": _vm.handleAddVendor
    },
    model: {
      value: _vm.purposeModal,
      callback: function callback($$v) {
        _vm.purposeModal = $$v;
      },
      expression: "purposeModal"
    }
  }, [_c('VendorCardContent', {
    ref: "TCF-vendor-card",
    staticStyle: {
      "min-height": "calc(100% - 80px)"
    },
    attrs: {
      "vendor-props": _vm.clickedVendor,
      "is-open": _vm.purposeModal
    }
  }), _c('div', {
    staticClass: "drawer-footer"
  }, [_vm.clickedVendor.declared_in_vl || _vm.clickedVendor.detected_in_cmp ? _c('div', [_c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.handleAddVendor
    }
  }, [_vm._v(" Update Vendors List ")])], 1) : _c('div', [_c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.handleAddVendor
    }
  }, [_vm._v(" Add Vendor to Vendors List ")])], 1)])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }