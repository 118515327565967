<template>
  <div>
    <Icon type="md-arrow-round-back"
          style="font-size:24px; position:absolute; top:15px;left:15px;cursor:pointer;color:#7A7A7A;"
          @click="$emit('close-view')"
          v-if="isViewerMode"
    />
    <div v-if="!isLoading"
         style="margin-top:24px"
    >
      <Row style="padding:25px; justify-content:space-around">
        <br>
        <Col v-if="vendor && vendor.logo">
          <img :src="getLogoUrl()"
               style="width:50px; height:50px"
          >
        </Col>
        <Col style="display:flex; flex-direction:column; margin: 0 auto">
          <span style="display:block;text-align:center;font-size:20px">{{ vendor ? vendor.name : 'No Name' }}</span>
          <span style="display:block;text-align:center">Trust index: <Tag type="dot"
                                                                          style="margin-left: 5px;"
                                                                          :color="vendorVigilance(vendor).color"
          >{{ vendor ? vendorVigilance(vendor).vigilance : 'No Trust index' }}</Tag></span>
        </Col>
      </Row>
      <Tabs v-model="activeTable">
        <TabPane label="Identity"
                 name="data"
        >
          <Row style="padding: 0 60px;margin-top:50px">
            <div class="vendor-field-container">
              <span class="vendor-field-title">Category</span>
              <span class="vendor-field-value">{{ vendor ? vendor.category.name : 'N/A' }}</span>
            </div>
            <div class="vendor-field-container">
              <span class="vendor-field-title">CMP declaration</span>
              <span class="vendor-field-value">
                {{ vendorProps ? vendorProps.detected_in_cmp ? 'Declared' : 'Not declared' : 'N/A' }}
                <!--                {{ vendor ? vendor.detected_in_cmp ? 'Declared' : 'Not declared' : 'N/A' }}-->
              </span>
            </div>
            <!-- <div class="vendor-field-container">
              <span class="vendor-field-title">Ads.txt</span>
              <span class="vendor-field-value">{{ vendor ? vendor.adstxtvendors.length ? this.$t('global.filters.in') : this.$t('global.filters.out') : 'N/A' }}</span>
            </div> -->
            <!--            <div class="vendor-field-container">-->
            <!--              <span class="vendor-field-title">In/Out TCF V2</span>-->
            <!--              <span class="vendor-field-value">{{-->
            <!--                vendor ? vendor.iabv2Id ? this.$t('global.filters.in') : this.$t('global.filters.out') : 'N/A'-->
            <!--              }}</span>-->
            <!--            </div>-->
            <div class="vendor-field-container">
              <span class="vendor-field-title">TCF v2 ID</span>
              <span class="vendor-field-value">{{ (vendor && vendor.iabv2Id) ? vendor.iabv2Id : 'N/A' }}</span>
            </div>
            <div class="vendor-field-container">
              <span class="vendor-field-title">Privacy</span>
              <span class="vendor-field-value">
                <Tooltip :content="vendor ? vendor.privacyPolicyUrl : 'N/A'"
                         max-width="200"
                         placement="top-start"
                >
                  {{ vendor ? ellipsis(vendor.privacyPolicyUrl) : 'N/A' }}
                </Tooltip></span>
            </div>
            <div class="vendor-field-container">
              <span class="vendor-field-title">General contact</span>
              <span class="vendor-field-value">
                <Tooltip :content="vendor ? vendor.websiteContactEmail : 'N/A'"
                         max-width="200"
                         placement="top-start"
                >
                  {{ vendor ? ellipsis(vendor.websiteContactEmail) : 'N/A' }}
                </Tooltip></span>
            </div>
            <div class="vendor-field-container">
              <span class="vendor-field-title">Legal contact</span>
              <span class="vendor-field-value">
                <Tooltip :content="vendor ? vendor.email : 'N/A'"
                         max-width="200"
                         placement="top-start"
                >
                  {{ vendor ? ellipsis(vendor.email) : 'N/A' }}
                </Tooltip></span>
            </div>
            <div class="vendor-field-container">
              <span class="vendor-field-title">Website</span>
              <span class="vendor-field-value">
                <Tooltip :content="vendor ? vendor.website : 'N/A'"
                         max-width="200"
                         placement="top-start"
                >
                  {{ vendor ? ellipsis(vendor.website) : 'N/A' }}
                </Tooltip></span>
            </div>
            <div class="vendor-field-container">
              <span class="vendor-field-title">Country</span>
              <span class="vendor-field-value">{{ vendor ? vendor.country.name : 'N/A' }}</span>
            </div>
            <div class="vendor-field-container">
              <span class="vendor-field-title">Country Protection</span>
              <span class="vendor-field-value">{{ vendorProps ? vendorProps.country_protection : 'N/A' }}</span>
            </div>
            <div class="vendor-field-container">
              <span class="vendor-field-title">Founded</span>
              <span class="vendor-field-value">{{ vendor ? vendor.foundedAt : 'N/A' }}</span>
            </div>
          </Row>
        </TabPane>
        <TabPane label="Purposes & features"
                 name="TCF"
        >
          <Alert type="warning"
                 show-icon
                 v-if="noPurposeNorFeatureSelected"
          >
            No purpose nor feature were selected, this vendor will be assigned the purpose "1 - Store and/or access information on a device" by default
          </Alert>
          <Alert type="warning"
                 show-icon
                 v-if="vendor.iabv2Id"
          >
            This vendor has declared purposes and features through IAB TCF v2 under vendor ID {{ vendor.iabv2Id }}, those are assigned by default and cannot be modified
          </Alert>
          <Collapse v-model="collapseVModel">
            <Panel name="1">
              Purposes
              <div slot="content">
                <div v-if="!(vendor && vendor.iabv2Id)">
                  <Row>
                    <Col :span="3"
                         style="text-align:center; min-height:32px;"
                    >
                      Consent
                    </Col>
                  </Row>
                  <Row v-for="(item, index) in default_tcfv2_f_and_p.purposes"
                       :key="'purpose' + index"
                       style="padding:2px"
                  >
                    <Col :span="3"
                         style="text-align:center"
                    >
                      <input type="checkbox"
                             :disabled="(vendor && vendor.iabv2Id)"
                             :id="item.id"
                             :value="item.id"
                             v-model="tcfv2_f_and_p.purposes"
                      >
                    </Col>
                    <Col :span="21">
                      {{ `${item.id} - ${item.name}` }}
                    </Col>
                  </Row>
                </div>
                <div v-else>
                  <Row>
                    <Col :span="3"
                         style="text-align:center; min-height:32px;"
                    >
                      Consent
                    </Col>
                    <Col :span="3"
                         style="text-align:center; min-height:32px;"
                    >
                      Flexible
                    </Col>
                    <Col :span="3"
                         style="text-align:center; min-height:32px;"
                    >
                      Legitimate interest
                    </Col>
                  </Row>
                  <Row v-for="(item, index) in default_tcfv2_f_and_p.purposes"
                       :key="'purpose' + index"
                       style="padding:2px"
                  >
                    <Col :span="3"
                         style="text-align:center"
                    >
                      <input type="checkbox"
                             :disabled="(vendor && vendor.iabv2Id)"
                             :id="item.id"
                             :value="item.id"
                             v-model="tcfv2_f_and_p.purposes"
                      >
                    </Col>
                    <Col :span="3"
                         style="text-align:center"
                    >
                      <input type="checkbox"
                             :disabled="(vendor && vendor.iabv2Id)"
                             :id="item.id"
                             :value="item.id"
                             v-model="tcfv2_f_and_p.flexiblePurposes"
                      >
                    </Col>
                    <Col :span="3"
                         style="text-align:center"
                    >
                      <input type="checkbox"
                             :disabled="(vendor && vendor.iabv2Id)"
                             :id="item.id"
                             :value="item.id"
                             v-model="tcfv2_f_and_p.legIntPurposes"
                      >
                    </Col>
                    <Col :span="15">
                      {{ `${item.id} - ${item.name}` }}
                    </Col>
                  </Row>
                </div>
              </div>
            </Panel>
            <Panel name="2">
              Special purposes
              <p slot="content">
                <Row>
                  <Col :span="3"
                       style="text-align:center; min-height:32px;"
                  >
                    Consent
                  </Col>
                </Row>
                <Row v-for="(item, index) in default_tcfv2_f_and_p.specialPurposes"
                     :key="'purpose' + index"
                     style="padding:2px"
                >
                  <Col :span="3"
                       style="text-align:center"
                  >
                    <input type="checkbox"
                           :disabled="(vendor && vendor.iabv2Id)"
                           :id="item.id"
                           :value="item.id"
                           v-model="tcfv2_f_and_p.specialPurposes"
                    >
                  </Col>
                  <Col :span="21">
                    {{ `${item.id} - ${item.name}` }}
                  </Col>
                </Row>
              </p>
            </Panel>
            <Panel name="3">
              Features
              <p slot="content" /><p slot="content">
                <Row>
                  <Col :span="3"
                       style="text-align:center; min-height:32px;"
                  >
                    Consent
                  </Col>
                </Row>
                <Row v-for="(item, index) in default_tcfv2_f_and_p.features"
                     :key="'purpose' + index"
                     style="padding:2px"
                >
                  <Col :span="3"
                       style="text-align:center"
                  >
                    <input type="checkbox"
                           :disabled="(vendor && vendor.iabv2Id)"
                           :id="item.id"
                           :value="item.id"
                           v-model="tcfv2_f_and_p.features"
                    >
                  </Col>
                  <Col :span="21">
                    {{ `${item.id} - ${item.name}` }}
                  </Col>
                </Row>
              </p>
            </Panel>
            <Panel name="4">
              Specials Features
              <p slot="content">
                <Row>
                  <Col :span="3"
                       style="text-align:center; min-height:32px;"
                  >
                    Consent
                  </Col>
                </Row>
                <Row v-for="(item, index) in default_tcfv2_f_and_p.specialFeatures"
                     :key="'purpose' + index"
                     style="padding:2px"
                >
                  <Col :span="3"
                       style="text-align:center"
                  >
                    <input type="checkbox"
                           :disabled="(vendor && vendor.iabv2Id)"
                           :id="item.id"
                           :value="item.id"
                           v-model="tcfv2_f_and_p.specialFeatures"
                    >
                  </Col>
                  <Col :span="21">
                    {{ `${item.id} - ${item.name}` }}
                  </Col>
                </Row>
              </p>
            </Panel>
          </Collapse>

          <!-- <div>
            <span style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:10px;display:block;margin-top:6px">Purposes</span>
            <div v-for="(item, index) in default_tcfv2_f_and_p.purposes"
                 :key="'purpose' + index"
            >
              <input type="checkbox"
                     :disabled="!tcfv2_f_and_p.purposes.includes(item.id) && (vendor && vendor.iabv2Id)"
                     :id="item.id"
                     :value="item.id"
                     v-model="tcfv2_f_and_p.purposes"
              >
              &nbsp;
              <input type="checkbox"
                     :id="item.id"
                     :value="item.id"
                     v-model="tcfv2_f_and_p.flexiblePurposes"
              >
              &nbsp;
              <input type="checkbox"
                     :id="item.id"
                     :value="item.id"
                     v-model="tcfv2_f_and_p.legIntPurposes"
              >
              <label :for="item.name"> {{ item.name }}</label>
            </div>
            <span>Checked {{ tcfv2_f_and_p.purposes }}</span>
            <span>Checked {{ tcfv2_f_and_p.flexiblePurposes }}</span>
            <span>Checked {{ tcfv2_f_and_p.legIntPurposes }}</span>
            <hr>
            <span style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:10px;display:block;margin-top:6px">Special purposes</span>
            <div v-for="(item, index) in default_tcfv2_f_and_p.specialPurposes"
                 :key="'specialPurpose' + index"
            >
              <input type="checkbox"
                     :id="item.id"
                     :value="item.id"
                     v-model="tcfv2_f_and_p.specialPurposes"
              >
              <label :for="item.name"> {{ item.name }}</label>
            </div>
            <span>Checked {{ tcfv2_f_and_p.specialPurposes }}</span>
            <hr>
            <span style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:10px;display:block;margin-top:6px">Features</span>
            <div v-for="(item, index) in default_tcfv2_f_and_p.features"
                 :key="'feature' + index"
            >
              <input type="checkbox"
                     :id="item.id"
                     :value="item.id"
                     v-model="tcfv2_f_and_p.features"
              >
              <label :for="item.name"> {{ item.name }}</label>
            </div>
            <span>Checked {{ tcfv2_f_and_p.features }}</span>
            <hr>
            <span style="border-bottom: 1px solid #e9e9e9;padding-bottom:6px;margin-bottom:10px;display:block;margin-top:6px">Special Features</span>
            <div v-for="(item, index) in default_tcfv2_f_and_p.specialFeatures"
                 :key="'specialFeature' + index"
            >
              <input type="checkbox"
                     :id="item.id"
                     :value="item.id"
                     v-model="tcfv2_f_and_p.specialFeatures"
              >
              <label :for="item.name"> {{ item.name }}</label>
            </div>
            <span>Checked {{ tcfv2_f_and_p.specialFeatures }}</span>
          </div> -->
        </TabPane>
      </Tabs>
      <!-- <div style="width:100%;display:flex;position:absolute; bottom:20px;left:0px;right:0px"
           v-if="!isCheckingPurposes || isViewerMode"
      >
        <Button type="primary"
                icon="md-add"
                style="margin: 0 auto"
                @click="declareVendorInList"
                v-if="vendor && !vendor.detected_in_cmp"
        >
          Declare in my vendor list
        </Button>
        <Button type="warning"
                v-else
                icon="md-remove"
                style="margin: 0 auto"
                @click="declareVendorInList"
        >
          Remove from the vendor list
        </Button>
      </div> -->
    </div>
    <Row v-else
         :gutter="24"
         :style="{position: 'relative', width: '100%', minHeight:'180px'}"
    >
      <Spin

        fix
      >
        <Icon
          type="ios-loading"
          size="26"
          class="demo-spin-icon-load"
        />
        <div>{{ $t('global.loading') }}</div>
      </Spin>
    </Row>
  </div>
</template>
<script>
import {api, TCFV2} from '@/config'
import {eventBus} from '@/main'
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'VendorCardContent',
  props: {
    isOpen: {
      type: Boolean,
      note: 'is the vendor detail panel open',
      default: false
    },
    vendorProps: {
      type: [Object],
      note: 'vendor object',
      default: null
    },
    isCheckingPurposes: {
      type: Boolean,
      note: 'if opnened for checking purpose before adding vendor in vl',
      default: true
    },
    isViewerMode: {
      type: Boolean,
      note: 'if the card is opened from view action',
      default: false
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler: function (val, _) {
        this.isLoading = true
        this.isDrawerOpen = val
        if (this.isCheckingPurposes) this.activeTable = 'TCF'
        val && this.getVendorByUuid()
      }
    },
    vendorProps: {
      deep: true,
      handler: function (val, _) {
        this.isLoading = true
        if (this.isCheckingPurposes) {
          this.activeTable = 'TCF'
        }
        this.getVendorByUuid()
        // console.log('yolo', val.tcfv2_f_and_p, this.tcfv2_f_and_p)
      }
    }
  },
  data () {
    return {
      collapseVModel: ['1'],
      bus: eventBus,
      activeTable: 'data',
      activeCollapse: ['1', '2', '3'],
      isDrawerOpen: this.isOpen,
      isLoading: false,
      checkingPuposes: false,
      vendor: null,
      default_tcfv2_f_and_p: TCFV2.FEATURES_AND_PURPOSES,
      tcfv2_f_and_p: {
        purposes: [],
        specialPurposes: [],
        legIntPurposes: [],
        flexiblePurposes: [],
        features: [],
        specialFeatures: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
    }),
    noPurposeNorFeatureSelected(){
      return (
        (
          this.tcfv2_f_and_p.purposes.length
          + this.tcfv2_f_and_p.flexiblePurposes.length
          + this.tcfv2_f_and_p.legIntPurposes.length
          + this.tcfv2_f_and_p.specialPurposes.length
          + this.tcfv2_f_and_p.features.length
          + this.tcfv2_f_and_p.specialFeatures.length
        ) == 0)
    }
  },
  methods: {
    ...mapActions({
      vendorGetVendorById: 'vendor/getVendorById',
      getVendorTCF: 'vendor/getVendorTCFDeclaration'
    }),
    getVendorByUuid () {
      return this.vendorGetVendorById(this.vendorProps.uuid)
        .then(response => {
          this.vendor = response.data
          this.vendor.is_exempted = !!this.vendor.is_exempted
          if (!this.vendor.country) this.vendor.country = {}
          if (!this.vendor.category) this.vendor.category = {}
          //reinit
          this.tcfv2_f_and_p = {
            purposes: [],
            specialPurposes: [],
            legIntPurposes: [],
            flexiblePurposes: [],
            features: [],
            specialFeatures: [],
          }

          // this test can only work with sent data from the table, not when adding a new vendor
          if (this.vendorProps.tcfv2_id !== null && this.vendorProps.iabvendor_tcfv2_f_and_p) {
            this.vendorProps.iabvendor_tcfv2_f_and_p && (this.tcfv2_f_and_p = Object.assign({}, this.vendorProps.iabvendor_tcfv2_f_and_p))
          }else if (this.vendor.iabv2Id !== null){
            this.getVendorTCF({id: this.vendor.iabv2Id})
              .then(r => {
                this.applyTCF(r.results)
              })
          }else{
            // load previously set tcf_f_and_p id available
            this.vendorProps.tcfv2_f_and_p && (this.tcfv2_f_and_p = Object.assign({}, this.vendorProps.tcfv2_f_and_p))
            // if(this.tcfv2_f_and_p.purposes.length == 0){
            //   this.tcfv2_f_and_p.purposes.push(1)
            // }
          }
          this.isLoading = false
        })
    },
    applyTCF (f_and_p) {
      // build declared tcfv2_f_and_p ids arrays for that vendor
      Object.keys(f_and_p).forEach((section, idx) => {
        f_and_p[section].forEach(f_or_p => {
          this.tcfv2_f_and_p[section].push(f_or_p.id)
        })
      })

    },
    declareVendorInList () {
      this.bus.$emit('add-vendor-in-list', [this.vendor])
    },
    ellipsis (string = '') {
      if (string && string.length > 30) {
        return string.substring(0, 27) + '...'
      }
      return string
    },
    getLogoUrl () {
      if (this.vendor && this.vendor.logo) {
        return `${api.root}img/vendors_updated/${this.vendor.logo}.png`
      }
      return ''
    },
    vendorVigilance (vendor) {
      if (!vendor) return {
        vigilance: 'N/A',
        color: 'grey'
      }
      const riskColor = {
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      }
      const vigilance = this.vendorVigilanceToLetter(vendor.vigilance_rating * 5)

      return {
        vigilance: vigilance,
        color: riskColor[vigilance]
      }
    },
  }
}
</script>
<style lang="scss">
.vendor-field-container {
  width: 100%;
  display: flex;
  margin: 5px 0;

  .vendor-field-title {
    width: 150px;
  }

  .vendor-field-value {
    font-weight: 700
  }
}
</style>
