var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isViewerMode ? _c('Icon', {
    staticStyle: {
      "font-size": "24px",
      "position": "absolute",
      "top": "15px",
      "left": "15px",
      "cursor": "pointer",
      "color": "#7A7A7A"
    },
    attrs: {
      "type": "md-arrow-round-back"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close-view');
      }
    }
  }) : _vm._e(), !_vm.isLoading ? _c('div', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('Row', {
    staticStyle: {
      "padding": "25px",
      "justify-content": "space-around"
    }
  }, [_c('br'), _vm.vendor && _vm.vendor.logo ? _c('Col', [_c('img', {
    staticStyle: {
      "width": "50px",
      "height": "50px"
    },
    attrs: {
      "src": _vm.getLogoUrl()
    }
  })]) : _vm._e(), _c('Col', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "margin": "0 auto"
    }
  }, [_c('span', {
    staticStyle: {
      "display": "block",
      "text-align": "center",
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.vendor ? _vm.vendor.name : 'No Name'))]), _c('span', {
    staticStyle: {
      "display": "block",
      "text-align": "center"
    }
  }, [_vm._v("Trust index: "), _c('Tag', {
    staticStyle: {
      "margin-left": "5px"
    },
    attrs: {
      "type": "dot",
      "color": _vm.vendorVigilance(_vm.vendor).color
    }
  }, [_vm._v(_vm._s(_vm.vendor ? _vm.vendorVigilance(_vm.vendor).vigilance : 'No Trust index'))])], 1)])], 1), _c('Tabs', {
    model: {
      value: _vm.activeTable,
      callback: function callback($$v) {
        _vm.activeTable = $$v;
      },
      expression: "activeTable"
    }
  }, [_c('TabPane', {
    attrs: {
      "label": "Identity",
      "name": "data"
    }
  }, [_c('Row', {
    staticStyle: {
      "padding": "0 60px",
      "margin-top": "50px"
    }
  }, [_c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Category")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(_vm._s(_vm.vendor ? _vm.vendor.category.name : 'N/A'))])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("CMP declaration")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(" " + _vm._s(_vm.vendorProps ? _vm.vendorProps.detected_in_cmp ? 'Declared' : 'Not declared' : 'N/A') + " ")])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("TCF v2 ID")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(_vm._s(_vm.vendor && _vm.vendor.iabv2Id ? _vm.vendor.iabv2Id : 'N/A'))])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Privacy")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_c('Tooltip', {
    attrs: {
      "content": _vm.vendor ? _vm.vendor.privacyPolicyUrl : 'N/A',
      "max-width": "200",
      "placement": "top-start"
    }
  }, [_vm._v(" " + _vm._s(_vm.vendor ? _vm.ellipsis(_vm.vendor.privacyPolicyUrl) : 'N/A') + " ")])], 1)]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("General contact")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_c('Tooltip', {
    attrs: {
      "content": _vm.vendor ? _vm.vendor.websiteContactEmail : 'N/A',
      "max-width": "200",
      "placement": "top-start"
    }
  }, [_vm._v(" " + _vm._s(_vm.vendor ? _vm.ellipsis(_vm.vendor.websiteContactEmail) : 'N/A') + " ")])], 1)]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Legal contact")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_c('Tooltip', {
    attrs: {
      "content": _vm.vendor ? _vm.vendor.email : 'N/A',
      "max-width": "200",
      "placement": "top-start"
    }
  }, [_vm._v(" " + _vm._s(_vm.vendor ? _vm.ellipsis(_vm.vendor.email) : 'N/A') + " ")])], 1)]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Website")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_c('Tooltip', {
    attrs: {
      "content": _vm.vendor ? _vm.vendor.website : 'N/A',
      "max-width": "200",
      "placement": "top-start"
    }
  }, [_vm._v(" " + _vm._s(_vm.vendor ? _vm.ellipsis(_vm.vendor.website) : 'N/A') + " ")])], 1)]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Country")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(_vm._s(_vm.vendor ? _vm.vendor.country.name : 'N/A'))])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Country Protection")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(_vm._s(_vm.vendorProps ? _vm.vendorProps.country_protection : 'N/A'))])]), _c('div', {
    staticClass: "vendor-field-container"
  }, [_c('span', {
    staticClass: "vendor-field-title"
  }, [_vm._v("Founded")]), _c('span', {
    staticClass: "vendor-field-value"
  }, [_vm._v(_vm._s(_vm.vendor ? _vm.vendor.foundedAt : 'N/A'))])])])], 1), _c('TabPane', {
    attrs: {
      "label": "Purposes & features",
      "name": "TCF"
    }
  }, [_vm.noPurposeNorFeatureSelected ? _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" No purpose nor feature were selected, this vendor will be assigned the purpose \"1 - Store and/or access information on a device\" by default ")]) : _vm._e(), _vm.vendor.iabv2Id ? _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" This vendor has declared purposes and features through IAB TCF v2 under vendor ID " + _vm._s(_vm.vendor.iabv2Id) + ", those are assigned by default and cannot be modified ")]) : _vm._e(), _c('Collapse', {
    model: {
      value: _vm.collapseVModel,
      callback: function callback($$v) {
        _vm.collapseVModel = $$v;
      },
      expression: "collapseVModel"
    }
  }, [_c('Panel', {
    attrs: {
      "name": "1"
    }
  }, [_vm._v(" Purposes "), _c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [!(_vm.vendor && _vm.vendor.iabv2Id) ? _c('div', [_c('Row', [_c('Col', {
    staticStyle: {
      "text-align": "center",
      "min-height": "32px"
    },
    attrs: {
      "span": 3
    }
  }, [_vm._v(" Consent ")])], 1), _vm._l(_vm.default_tcfv2_f_and_p.purposes, function (item, index) {
    return _c('Row', {
      key: 'purpose' + index,
      staticStyle: {
        "padding": "2px"
      }
    }, [_c('Col', {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        "span": 3
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.tcfv2_f_and_p.purposes,
        expression: "tcfv2_f_and_p.purposes"
      }],
      attrs: {
        "type": "checkbox",
        "disabled": _vm.vendor && _vm.vendor.iabv2Id,
        "id": item.id
      },
      domProps: {
        "value": item.id,
        "checked": Array.isArray(_vm.tcfv2_f_and_p.purposes) ? _vm._i(_vm.tcfv2_f_and_p.purposes, item.id) > -1 : _vm.tcfv2_f_and_p.purposes
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.tcfv2_f_and_p.purposes,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.tcfv2_f_and_p, "purposes", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.tcfv2_f_and_p, "purposes", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.tcfv2_f_and_p, "purposes", $$c);
          }
        }
      }
    })]), _c('Col', {
      attrs: {
        "span": 21
      }
    }, [_vm._v(" " + _vm._s("".concat(item.id, " - ").concat(item.name)) + " ")])], 1);
  })], 2) : _c('div', [_c('Row', [_c('Col', {
    staticStyle: {
      "text-align": "center",
      "min-height": "32px"
    },
    attrs: {
      "span": 3
    }
  }, [_vm._v(" Consent ")]), _c('Col', {
    staticStyle: {
      "text-align": "center",
      "min-height": "32px"
    },
    attrs: {
      "span": 3
    }
  }, [_vm._v(" Flexible ")]), _c('Col', {
    staticStyle: {
      "text-align": "center",
      "min-height": "32px"
    },
    attrs: {
      "span": 3
    }
  }, [_vm._v(" Legitimate interest ")])], 1), _vm._l(_vm.default_tcfv2_f_and_p.purposes, function (item, index) {
    return _c('Row', {
      key: 'purpose' + index,
      staticStyle: {
        "padding": "2px"
      }
    }, [_c('Col', {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        "span": 3
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.tcfv2_f_and_p.purposes,
        expression: "tcfv2_f_and_p.purposes"
      }],
      attrs: {
        "type": "checkbox",
        "disabled": _vm.vendor && _vm.vendor.iabv2Id,
        "id": item.id
      },
      domProps: {
        "value": item.id,
        "checked": Array.isArray(_vm.tcfv2_f_and_p.purposes) ? _vm._i(_vm.tcfv2_f_and_p.purposes, item.id) > -1 : _vm.tcfv2_f_and_p.purposes
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.tcfv2_f_and_p.purposes,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.tcfv2_f_and_p, "purposes", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.tcfv2_f_and_p, "purposes", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.tcfv2_f_and_p, "purposes", $$c);
          }
        }
      }
    })]), _c('Col', {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        "span": 3
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.tcfv2_f_and_p.flexiblePurposes,
        expression: "tcfv2_f_and_p.flexiblePurposes"
      }],
      attrs: {
        "type": "checkbox",
        "disabled": _vm.vendor && _vm.vendor.iabv2Id,
        "id": item.id
      },
      domProps: {
        "value": item.id,
        "checked": Array.isArray(_vm.tcfv2_f_and_p.flexiblePurposes) ? _vm._i(_vm.tcfv2_f_and_p.flexiblePurposes, item.id) > -1 : _vm.tcfv2_f_and_p.flexiblePurposes
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.tcfv2_f_and_p.flexiblePurposes,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.tcfv2_f_and_p, "flexiblePurposes", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.tcfv2_f_and_p, "flexiblePurposes", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.tcfv2_f_and_p, "flexiblePurposes", $$c);
          }
        }
      }
    })]), _c('Col', {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        "span": 3
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.tcfv2_f_and_p.legIntPurposes,
        expression: "tcfv2_f_and_p.legIntPurposes"
      }],
      attrs: {
        "type": "checkbox",
        "disabled": _vm.vendor && _vm.vendor.iabv2Id,
        "id": item.id
      },
      domProps: {
        "value": item.id,
        "checked": Array.isArray(_vm.tcfv2_f_and_p.legIntPurposes) ? _vm._i(_vm.tcfv2_f_and_p.legIntPurposes, item.id) > -1 : _vm.tcfv2_f_and_p.legIntPurposes
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.tcfv2_f_and_p.legIntPurposes,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.tcfv2_f_and_p, "legIntPurposes", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.tcfv2_f_and_p, "legIntPurposes", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.tcfv2_f_and_p, "legIntPurposes", $$c);
          }
        }
      }
    })]), _c('Col', {
      attrs: {
        "span": 15
      }
    }, [_vm._v(" " + _vm._s("".concat(item.id, " - ").concat(item.name)) + " ")])], 1);
  })], 2)])]), _c('Panel', {
    attrs: {
      "name": "2"
    }
  }, [_vm._v(" Special purposes "), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('Row', [_c('Col', {
    staticStyle: {
      "text-align": "center",
      "min-height": "32px"
    },
    attrs: {
      "span": 3
    }
  }, [_vm._v(" Consent ")])], 1), _vm._l(_vm.default_tcfv2_f_and_p.specialPurposes, function (item, index) {
    return _c('Row', {
      key: 'purpose' + index,
      staticStyle: {
        "padding": "2px"
      }
    }, [_c('Col', {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        "span": 3
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.tcfv2_f_and_p.specialPurposes,
        expression: "tcfv2_f_and_p.specialPurposes"
      }],
      attrs: {
        "type": "checkbox",
        "disabled": _vm.vendor && _vm.vendor.iabv2Id,
        "id": item.id
      },
      domProps: {
        "value": item.id,
        "checked": Array.isArray(_vm.tcfv2_f_and_p.specialPurposes) ? _vm._i(_vm.tcfv2_f_and_p.specialPurposes, item.id) > -1 : _vm.tcfv2_f_and_p.specialPurposes
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.tcfv2_f_and_p.specialPurposes,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.tcfv2_f_and_p, "specialPurposes", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.tcfv2_f_and_p, "specialPurposes", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.tcfv2_f_and_p, "specialPurposes", $$c);
          }
        }
      }
    })]), _c('Col', {
      attrs: {
        "span": 21
      }
    }, [_vm._v(" " + _vm._s("".concat(item.id, " - ").concat(item.name)) + " ")])], 1);
  })], 2)]), _c('Panel', {
    attrs: {
      "name": "3"
    }
  }, [_vm._v(" Features "), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('Row', [_c('Col', {
    staticStyle: {
      "text-align": "center",
      "min-height": "32px"
    },
    attrs: {
      "span": 3
    }
  }, [_vm._v(" Consent ")])], 1), _vm._l(_vm.default_tcfv2_f_and_p.features, function (item, index) {
    return _c('Row', {
      key: 'purpose' + index,
      staticStyle: {
        "padding": "2px"
      }
    }, [_c('Col', {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        "span": 3
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.tcfv2_f_and_p.features,
        expression: "tcfv2_f_and_p.features"
      }],
      attrs: {
        "type": "checkbox",
        "disabled": _vm.vendor && _vm.vendor.iabv2Id,
        "id": item.id
      },
      domProps: {
        "value": item.id,
        "checked": Array.isArray(_vm.tcfv2_f_and_p.features) ? _vm._i(_vm.tcfv2_f_and_p.features, item.id) > -1 : _vm.tcfv2_f_and_p.features
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.tcfv2_f_and_p.features,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.tcfv2_f_and_p, "features", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.tcfv2_f_and_p, "features", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.tcfv2_f_and_p, "features", $$c);
          }
        }
      }
    })]), _c('Col', {
      attrs: {
        "span": 21
      }
    }, [_vm._v(" " + _vm._s("".concat(item.id, " - ").concat(item.name)) + " ")])], 1);
  })], 2)]), _c('Panel', {
    attrs: {
      "name": "4"
    }
  }, [_vm._v(" Specials Features "), _c('p', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('Row', [_c('Col', {
    staticStyle: {
      "text-align": "center",
      "min-height": "32px"
    },
    attrs: {
      "span": 3
    }
  }, [_vm._v(" Consent ")])], 1), _vm._l(_vm.default_tcfv2_f_and_p.specialFeatures, function (item, index) {
    return _c('Row', {
      key: 'purpose' + index,
      staticStyle: {
        "padding": "2px"
      }
    }, [_c('Col', {
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        "span": 3
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.tcfv2_f_and_p.specialFeatures,
        expression: "tcfv2_f_and_p.specialFeatures"
      }],
      attrs: {
        "type": "checkbox",
        "disabled": _vm.vendor && _vm.vendor.iabv2Id,
        "id": item.id
      },
      domProps: {
        "value": item.id,
        "checked": Array.isArray(_vm.tcfv2_f_and_p.specialFeatures) ? _vm._i(_vm.tcfv2_f_and_p.specialFeatures, item.id) > -1 : _vm.tcfv2_f_and_p.specialFeatures
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.tcfv2_f_and_p.specialFeatures,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = item.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.tcfv2_f_and_p, "specialFeatures", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.tcfv2_f_and_p, "specialFeatures", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.tcfv2_f_and_p, "specialFeatures", $$c);
          }
        }
      }
    })]), _c('Col', {
      attrs: {
        "span": 21
      }
    }, [_vm._v(" " + _vm._s("".concat(item.id, " - ").concat(item.name)) + " ")])], 1);
  })], 2)])], 1)], 1)], 1)], 1) : _c('Row', {
    style: {
      position: 'relative',
      width: '100%',
      minHeight: '180px'
    },
    attrs: {
      "gutter": 24
    }
  }, [_c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "26"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }