<template>
  <main v-if="qs">
    <VendorListActions @handleWidgetClick="val => handleWidgetClick(val)" />
    <VendorListTable :active-table="activeTable" />
  </main>
</template>
<script>

import VendorListActions from './VendorListActions.vue'
import VendorListTable from './VendorListTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'VendorList',
  components: {
    VendorListActions,
    VendorListTable
  },

  data () {
    return {
      activeTable: 'totalDetected',

    }
  },
  watch: {
    declaredVendors: {
      deep: true,
      handler: function (val, old) {
        if (!val.length) this.helpModal = true
      }
    }
  },
  computed: {
    ...mapGetters({
      qs: 'scope/qs',
      declaredVendors: 'vendor/declaredVendors'
    })
  },
  methods: {
    handleWidgetClick (val) {
      this.activeTable = val
    }
  }
}
</script>
<style lang="scss">
.vlm-help-modal {
  .ivu-modal-header-inner {
    text-align: center;
  }
  .ivu-modal-footer {
    display: none
  }
}
</style>
