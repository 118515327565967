var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticStyle: {
      "padding": "24px"
    }
  }, [_vm.step === 0 ? _c('div', [_vm._m(0), _c('br'), _c('br'), _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" Provided metrics are related to the selected period ")]), _c('br'), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.img1Url
    }
  })], 1) : _vm._e(), _vm.step === 1 ? _c('div', [_c('p', [_vm._v(" Clicking on one of the indicators to display additional data ")]), _c('p', [_vm._v("For example, clicking on the \"Active but not in CMP\" indicator will display a detailed list of vendors that are active on your properties but not declared in your CMP:")]), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.img2Url
    }
  })]) : _vm._e(), _vm.step === 2 ? _c('Row', [_c('Row', [_c('p', [_vm._v(" For more granular information, you can search for a specific vendor: ")]), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.img3Url
    }
  })])], 1) : _vm._e(), _vm.step === 3 ? _c('Row', [_c('Row', [_c('p', [_vm._v(" Add or remove vendors using the + and - icons. ")]), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.img4Url
    }
  })])], 1) : _vm._e(), _vm.step === 4 ? _c('Row', [_c('Row', [_c('p', [_vm._v("Adding or removing vendors will impact the indicators next time your site is scanned")]), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.img5Url
    }
  })])], 1) : _vm._e(), _vm.step === 5 ? _c('Row', [_c('Row', [_c('p', [_vm._v("You can then export your new vendor list by clicking the \"Export the Vendor list Project\" button and implement it in your CMP")]), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.img7Url
    }
  }), _c('img', {
    staticStyle: {
      "width": "100%",
      "margin": "42px auto",
      "border": "1px solid rgba(0,0,0,0.2)"
    },
    attrs: {
      "src": _vm.img8Url
    }
  })])], 1) : _vm._e(), _c('Row', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "type": "flex"
    }
  }, [_c('Steps', {
    staticStyle: {
      "width": "300px",
      "margin": "0 auto"
    },
    attrs: {
      "current": _vm.step
    }
  }, [_c('Step', {
    staticStyle: {
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.step = 0;
      }
    }
  }), _c('Step', {
    staticStyle: {
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.step = 1;
      }
    }
  }), _c('Step', {
    staticStyle: {
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.step = 2;
      }
    }
  }), _c('Step', {
    staticStyle: {
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.step = 3;
      }
    }
  }), _c('Step', {
    staticStyle: {
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.step = 4;
      }
    }
  }), _c('Step', {
    staticStyle: {
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.step = 5;
      }
    }
  })], 1), _c('Button', {
    staticStyle: {
      "position": "absolute",
      "bottom": "15px",
      "right": "15px"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.step === 5 ? _vm.$emit('close-help') : _vm.next();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.step === 5 ? 'Done' : 'Next step') + " ")])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Welcome to your Vendor List Manager. "), _c('br'), _c('br'), _vm._v(" Here, you're able to monitor all the vendor activity on your website, and manage vendors whether they're active and/or declared in your CMP. ")]);

}]

export { render, staticRenderFns }