<template>
  <div>
    <Row type="flex"
         style="text-align:center"
         class="save-vendor-modal"
    >
      <Col span="24">
        <span>Here are the changes you've made to your vendor list on Facettes</span>
      </Col>
      <Col span="24"
           class="box"
      >
        {{ draftVendorList.filter(elm => elm.declared_in_vl).length }} New Declared Vendors
      </Col>
      <!-- <Col style="margin-top:24px"
           span="24"
      >
        {{removedVendors()}} Removed Vendors
      </Col> -->
      <Col style="margin-top:24px"
           span="24"
      >
        <span>This new list has</span>
      </Col>
      <Col class="box"
           span="24"
      >
        {{ draftVendorList.length }} Declared Vendors
      </Col>
    </Row>
    <br>
    <div @click="showNoPurposeDraftVendorListVendors = !showNoPurposeDraftVendorListVendors">
      <Alert type="error"
             show-icon
             v-if="noPurposeDraftVendorListVendors.length"
      >
        {{ noPurposeDraftVendorListVendors.length }} vendor(s) in you list have no declared purposes and will be assigned the purpose "1 - Store and/or access information on a device" or "cookies" by default for exports
        <div v-if="showNoPurposeDraftVendorListVendors">
          <br>
          <p v-for="(item, index) in noPurposeDraftVendorListVendors"
             :key="'noPurposeDraftVendorListVendors' + index"
          >
            - {{ item.name }}
          </p>
        </div>
      </Alert>
    </div>
    <Alert type="warning"
           show-icon
    >
      For your changes to be reflected live on your properties, make sure to import the downloaded JSON/CSV file to your CMP.
    </Alert>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  data () {
    return {
      showNoPurposeDraftVendorListVendors : false
    }
  },
  computed: {
    ...mapGetters({
      draftVendorList: 'vendor/draftVendorList',
      baseVendorListState: 'vendor/baseVendorListState',
    }),
    noPurposeDraftVendorListVendors(){
      return this.draftVendorList.filter(elm => (!elm.iabvendor_tcfv2_f_and_p && (!elm.tcfv2_f_and_p  || elm.tcfv2_f_and_p?.purposes?.length == 0)))
    }
  },
  methods: {
    removedVendors () {
      if (!this.baseVendorListState) return 0
      return this.baseVendorListState.amountDeclared < this.draftVendorList.length ? 0 : this.baseVendorListState.amountDeclared - this.draftVendorList.length
    }
  }
}
</script>
<style lang="scss">
.save-vendor-modal {
.box {
    max-width: 190px;
    margin: 10px auto;
    padding: 12px;
    background: #ebebeb;
    font-weight: 700;
}
}

</style>
